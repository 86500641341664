import { LOGIN_CHILD_DETAIL ,LOGIN_STUDENT_DETAIL} from "../action/actionConstant/action_constant";
const initialState = [];
export default function childReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_CHILD_DETAIL:
      return {
        loggedInChild: action.Payload,
        // { ...state }
      };

      case LOGIN_STUDENT_DETAIL:
      return {
        loggedInStudent: action.Payload,
        // { ...state }
      };

    default:
      return { ...state };
  }
}

