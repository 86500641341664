import { LOGIN_USER_DETAILS } from "../action/actionConstant/action_constant";
const initialState = [];
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER_DETAILS:
      return {
        loggedInUser: action.Payload,
        // { ...state }
      };

    default:
      return { ...state };
  }
}
