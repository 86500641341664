import axios from "axios";
import toastr from "toastr";
import { gapi } from 'gapi-script';
import { OAuth2Client } from 'google-auth-library';
// import google from 'googleapis';
import moment from 'moment';
import {
    LOGIN_USER_DETAILS, GET_COUNSELOR_VIDEOS, LOGIN_CHILD_DETAIL, LOGIN_STUDENT_DETAIL
} from "./actionConstant/action_constant";
import "../../node_modules/toastr/build/toastr.css";
import url from "../config/config";
 
const client = new OAuth2Client("592837005360-j7ocgkb2nkp134jsdfbqm5uqgc1s63uf.apps.googleusercontent.com");

//------------user routes for login------------------//
export function userLoginDetails(data) {
    return {
        type: LOGIN_USER_DETAILS,
        Payload: data
    };
}

export function childLoginDetails(data) {
    return {
        type: LOGIN_CHILD_DETAIL,
        Payload: data
    };
}

export function studentLoginDetails(data) {
    return {
        type: LOGIN_STUDENT_DETAIL,
        Payload: data
    };
}

export function getCounselorVideos(data) {
    return {
        type: GET_COUNSELOR_VIDEOS,
        Payload: data
    };
}

export function createUserAccount(data, history) {
 
    return function (dispatch) {
        return axios
            .post(url + "/user/createAccount", { data })
            .then(res => {
                if (res.data.status === 200) {
                    if(data.role==5){
                        return true;
                    }else{
                        history.push({ pathname: "/email-verification-page" });
                    }
                    return true;
                } else toastr.error(res.data.msg);
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function createParentAccount(data) {
    return function (dispatch) {
        return axios
            .post(url + "/user/createParentAccount", { data })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success("Registration sucessfull, Please check your email to verify your account.");
                    // this.history.push({ pathname: "/email-verification-page" });
                    return true;
                } else toastr.error(res.data.msg);
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}



export function getStudentForSchool(data) {
    return function (dispatch) {
        return axios
            .post(url + "/user/studentForSelectedSchool", { data })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success("Registration sucessfull, Please check your email to verify your account.");
                    // this.history.push({ pathname: "/email-verification-page" });
                    return res.data
                } else toastr.error(res.data.msg);
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function LoginAction(data, history) {
    return function (dispatch) {
        return axios
            .post(url + "/user/login", { data })
            .then(res => {
                if (res.data.status === 200) {
                    if (res.data.data === undefined) {
                       return true;
                    }
                    else {
 
                        if (res.data.data.role === 4) {
                            
                            if (res.data.data.childData) {
                                res.data.data.childData.map((child, index) => {
                                    if (data.username === child.nickName) {
                                        dispatch(childLoginDetails(child));
                                    }
                                })
                                localStorage.setItem("token", res.data.data.token);
                                localStorage.setItem("user", data.username);
                                localStorage.setItem("usertype", "user");
                                localStorage.setItem("isChildLogin", true);
                                dispatch(userLoginDetails(res.data.data.userdetailsdata));
                                if (res.data.data.isParent = true) {
                                    //console.log(res.data.data.isParent)
                                    window.location.href = ('/dashboard')
                                }
                                else {
                                    history.push({ pathname: "/dashboard" });

                                }
                            }
                        }
                        else if (res.data.data.role === 7) {
                            var studentInfo = res.data.data.studentData[0];
 
                            if (studentInfo) {
                                /* res.data.data.studentData.map((student, index) => {
                                    if (data.guardianEmail === student.guardianEmail) {
                                        //console.log("hell")
                                        localStorage.setItem("user", student.studentName);

                                        dispatch(studentLoginDetails(student));
                                    }
                                }) */

                                localStorage.setItem("user", studentInfo.studentName);
                                dispatch(studentLoginDetails(studentInfo));

                                localStorage.setItem("token", res.data.data.token);
                                 

                                localStorage.setItem("usertype", "user");
                                localStorage.setItem("isStudentLogin", true);
                                dispatch(userLoginDetails(res.data.data.userdetailsdata));

                                history.push({ pathname: "/dashboard" });
 
                            }
                        }


                        else {
                            //console.log('Counselor Login');
                            localStorage.setItem("token", res.data.data.token);
                            localStorage.setItem("usertype", "user");
                            localStorage.setItem("isChildLogin", false);
                            localStorage.setItem("calendar", "calendar");
                            localStorage.setItem("messages", "messages");
                            localStorage.setItem("documents", "documents");
                            localStorage.setItem("revenue", "revenue");
                            dispatch(userLoginDetails(res.data.data.userdetailsdata));
                            history.push({ pathname: "/dashboard" });
                        }
                        return true;
                    }
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                //console.log(err)
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function LoginActionOther(data, history) {
    //console.log(data)
    return function (dispatch) {
        return axios
            .post(url + "/user/loginOther", { data })
            .then(res => {
                if (res.data.status === 200) {
                    if (res.data.data === undefined) {
                        // toastr.success(res.data.msg);
                        return true;
                    }
                    else {


                        if (res.data.data.role === 4) {
                          
                            if (res.data.data.childData) {
                                res.data.data.childData.map((child, index) => {
                                    if (data.username === child.nickName) {
                                        dispatch(childLoginDetails(child));
                                    }
                                })
                                localStorage.setItem("token", res.data.data.token);
                                localStorage.setItem("user", data.username);
                                localStorage.setItem("usertype", "user");
                                localStorage.setItem("isChildLogin", true);
                                dispatch(userLoginDetails(res.data.data.userdetailsdata));
                                if (res.data.data.isParent = true) {
                                    //console.log(res.data.data.isParent)
                                    window.location.href = ('/dashboard')
                                }
                                else {
                                    history.push({ pathname: "/dashboard" });

                                }
                            }
                        }
                        else if (res.data.data.role === 7) {
                            //console.log(res.data.data.userdetailsdata,"student response login action")
                            //console.log(res.data.data.studentData,"student data login action")

                            if (res.data.data.studentData) {
                                res.data.data.studentData.map((student, index) => {
                                    if (data.guardianEmail === student.guardianEmail) {
                                        //console.log("hell")
                                        localStorage.setItem("user", student.studentName);

                                        dispatch(studentLoginDetails(student));
                                    }
                                })

                                localStorage.setItem("token", res.data.data.token);
                                //localStorage.setItem("user", data.guardianEmail);

                                localStorage.setItem("usertype", "user");
                                localStorage.setItem("isStudentLogin", true);
                                dispatch(userLoginDetails(res.data.data.userdetailsdata));

                                history.push({ pathname: "/dashboard" });


                            }
                        }


                        else {
                            localStorage.setItem("token", res.data.data.token);
                            localStorage.setItem("usertype", "user");
                            localStorage.setItem("isChildLogin", false);
                            localStorage.setItem("calendar", "calendar");
                            localStorage.setItem("messages", "messages");
                            localStorage.setItem("documents", "documents");
                            localStorage.setItem("revenue", "revenue");
                            dispatch(userLoginDetails(res.data.data.userdetailsdata));
                            history.push({ pathname: "/dashboard" });
                        }
                        return true;
                    }
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                //console.log(err)
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function getUserData(data) {

    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/getUserData", { headers: headers })
            .then(async(res) => {
                if (res.data.status === 200) {
                   
                    //console.log(res.data, "user data")
                    dispatch(userLoginDetails(res.data.data));

                    if (localStorage.getItem("isChildLogin") === "true") {
                        //console.log(res.data,"child data")

                        res.data.data.childInfo.map((child, index) => {
                            //console.log(localStorage.getItem("user") === child.nickName)
                            if (localStorage.getItem("user") === child.nickName) {
                                dispatch(childLoginDetails(child));
                            }

                        })
                        return true
                    }

                    else {
                        //console.log(res.data,"student data")
                        res.data.data.studentInfo.map((student, index) => {
                            //console.log(localStorage.getItem("user") === student.studentName)
                            if (localStorage.getItem("user") === student.studentName) {
                                dispatch(studentLoginDetails(student));
                            }
                        })
                        return true;
                    }

                }
                else if (res.data.status === 401) {
                   //window.location.href = ('/')
                }
            })
            .catch(err => {
                console.log(err);
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getParentData() {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/getParentData", { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(parent(res.data.data));
                    return res.data.data;
                } else if (res.data.status === 401) {
                    // dispatch(userLoginDetails(''));
                    // toastr.error(" ! Invalid access");
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getSchoolUnderData(data, history) {
    //console.log(data)
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getSchoolUnderData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}





export function getSchoolUnderDataDistrict(data, history) {
    //console.log(data)
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getSchoolUnderDataDistrict", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


//GET ADMIN UNDER SCHOOL

export function getSchoolDataAdmin(data, history) {
    
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getSchoolDataAdmin", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

//getSchoolAdminData

export function getSchoolAdminData(data, history) {
     
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getSchoolAdminData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}
//school admin data

export function getDistrictAdminData(data, history) {
     
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getDistrictAdminData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    return false;
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

//Admin Student Profile

export function getSchoolAdminStudent(data, history) {
     
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/getSchoolAdminStudent", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getDistrictSchoolAdminData(data, history) {
     
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getDistrictSchoolAdminData ", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}



export function logout(data, history) {
    // return function (dispatch) {

    //     dispatch(userLoginDetails(''));
    //     history.push({ pathname: "/" });
    //     return null

    // };

    // axios.defaults.headers.common["Authorization"] = localStorage.getItem(
    //     "token"
    // );
    // var headers = {
    //     "Content-Type": "application/json"
    // };
    return function (dispatch) {
        return axios
            .post(url + "/user/logout", { data })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(parent(res.data.data));
                    dispatch(userLoginDetails(null));
                    history.push({ pathname: "/" });
                    return true;
                } else if (res.data.status === 401) {
                    // dispatch(userLoginDetails(''));
                    // toastr.error(" ! Invalid access");
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function stepVerificationProccess(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        if (data.role === 2 && data.step === 2) {

            var arrayData = JSON.stringify(data.educationInfo);
            const formData = new FormData();
            formData.append("resume", data.resume);
            formData.append("phone", data.phone);
            formData.append("educationInfo", arrayData);
            formData.append("role", data.role);
            formData.append("step", data.step);
            formData.append("linkUrl", data.linkUrl);
            //console.log(formData)
            return axios
                .post(url + "/user/stepVerification", formData, { headers: headers })
                .then(res => {

                    if (res.data.status === 200) {
                        // dispatch(userLoginDetails(res.data.data));
                        return true;
                    } else {
                        toastr.error(res.data.msg);
                    }
                })

                .catch(err => {
                    toastr.error("Something went wrong, Please try again");
                });
        } else {
            return axios
                .post(url + "/user/stepVerification", { data }, { headers: headers })
                .then(res => {
                    return res.data;
                })                
                .catch(err => {
                    toastr.error("Something went wrong, Please try again");
                });
        }

    };
}

export function SaveSubscription(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    
    return function (dispatch) {
        return axios
            .post(url + "/subscription/saveSubscription", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    dispatch(userLoginDetails(res.data.data));
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function SaveSubscriptionSchoolAdmin(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };



    return function (dispatch) {
        
        return axios
            .post(url + "/subscription/saveSubscriptionSchoolAdmin", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {

                    dispatch(userLoginDetails(res.data.data));
                    // //console.log(data)

                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            //     const formData = new FormData();
            //  formData.append("myResume", this.resumeDetails);
            //  formData.append("data", data);
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });

    };
}


export function SaveSubscriptionDistrictAdmin(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };



    return function (dispatch) {
        return axios
            .post(url + "/subscription/saveSubscriptionDistrictAdmin", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {

                    dispatch(userLoginDetails(res.data.data));
                    // //console.log(data)

                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            //     const formData = new FormData();
            //  formData.append("myResume", this.resumeDetails);
            //  formData.append("data", data);
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });

    };
}

export function SavePayment(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/paymentInfo/savePaymentInfo", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });

    };
}

export function Payment(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    )
    var headers = {
        "Content-Type": "application/json"
    }
    return function (dispatch) {
        return axios
            .post(url + "/user/payment", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data;
                } else {
                    return res.data;
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });

    };
}

export function ForgetPassword(data, history) {
    return function (dispatch) {
        return axios
            .post(url + "/user/forgotPassword", { data })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg);
                    return true
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function ResetPasswordForUser(password, token, history) {
    return function (dispatch) {
        let headers = {
            "Content-Type": "application/json"
        };
        return axios
            .post(url + "/user/resetPassword", { password, token }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    history.push({ pathname: "/" });
                    return true
                }
                else {
                    toastr.error(res.data.msg);
                    return false;
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function ResetPasswordForStudent(password, token, history) {
    return function (dispatch) {
        let headers = {
            "Content-Type": "application/json"
        };
        return axios
            .post(url + "/user/resetPasswordStudent", { password, token }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    history.push({ pathname: "/" });
                    return true
                }
                else {
                    toastr.error(res.data.msg);
                    return false;
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function accountVerification(token, history) {
    return function (dispatch) {
        return axios
            .post(url + "/user/accountVerification", { token })
            .then(res => {
                if (res.data.status === 200) {
                    // if(res.data.data.userdetailsdata.role === 2) {
                    localStorage.setItem("token", res.data.data.token);
                    localStorage.setItem("usertype", "user");
                    dispatch(userLoginDetails(res.data.data.userdetailsdata));
                    history.push({ pathname: "/dashboard" });
                    // }
                    return true
                } else {
                    toastr.error(res.data.msg);
                    history.push({ pathname: "/" });
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getGaurdianData(token, history) {
    return function (dispatch) {
        return axios
            .post(url + "/user/getGaurdianData", { token })
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.setItem("token", res.data.data.token);
                    localStorage.setItem("usertype", "user");
                    localStorage.setItem("isChildLogin", false);
                    dispatch(userLoginDetails(res.data.data.userdetailsdata));
                    if (res.data.data.userdetailsdata.role === 2) {
                        localStorage.setItem("calendar", "calendar");
                        localStorage.setItem("messages", "messages");
                        localStorage.setItem("documents", "documents");
                        localStorage.setItem("revenue", "revenue");
                    }
                    return true
                } else {
                    toastr.error(res.data.msg);
                    history.push({ pathname: "/" });
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function uploadVideoForKids(data, history) {
    //console.log(data)
    axios.defaults.headers.common["Authorization"] =
        localStorage.getItem(
            "token"
        );
        
    var headers = {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
    };
    return function (dispatch) {
        const formData = new FormData();

        if (data.childId) {
 
            formData.append("videodata", data.videodata);
            formData.append("parentId", data.parentId);
            formData.append("childId", data.childId);
            formData.append("parentName", data.parentName);
            formData.append("childName", data.childName);
            formData.append("image", data.image);
            formData.append("parentEmail", data.parentEmail);
            formData.append("councellorId", data.councellorId)
        }
        else {
 
            formData.append("videodata", data.videodata);
            formData.append("schoolId", data.schoolId);
            formData.append("studentId", data.studentId);
            formData.append("guardianName", data.guardianName);
            formData.append("studentName", data.studentName);
            formData.append("image", data.image);
            formData.append("guardianEmail", data.guardianEmail);
            formData.append("councellorId", data.councellorId)
        }
 
        return axios
            .post(url + "/children/uploadVideoForKids", formData, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function uploadVideo(data, history) {
    //console.log(data.assignmentpath)
    //console.log(data);
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {

        const formData = new FormData();
        formData.append("videodata", data.videodata);
        formData.append("thumbnailpath", data.thumbnailpath);
        formData.append("assignmentpath", data.assignmentpath);
        formData.append("emojiData", data.emojiData);
        formData.append("description", data.description);
        formData.append("tags", data.tags);
        formData.append("role", data.role);
        formData.append("email", data.email);
        formData.append("firstName", data.firstName);
        formData.append("lastName", data.lastName);
        formData.append("therapyType", JSON.stringify(data.therapyType));
        formData.append("ageRange", JSON.stringify(data.ageRange));
        formData.append("image", data.image);
        formData.append("materials", JSON.stringify(data.materials));
        formData.append("shortSummary", data.shortSummary);
        //console.log(formData);
        return axios
            .post(url + "/counselor/uploadVideo", formData, { headers: headers })
            .then(res => {
                //console.log(res,"res")
                if (res.data.status === 200) {
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                //console.log('Error:'+err);
                toastr.error("Something went wrong, Please try again");
            });

    };
}

export function updateVideo(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        //console.log(data,"assignment")
        if (data.thumbnailpath || data.videodata || data.assignmentpath) {
            const formData = new FormData();
            if (data.videodata) {
                formData.append("videodata", data.videodata);
            }
            if (data.thumbnailpath) {
                formData.append("thumbnailpath", data.thumbnailpath);
            }
            if (data.assignmentpath) {
                formData.append("assignmentpath", data.assignmentpath);
            }
            formData.append("emojiData", data.emojiData);
            formData.append("description", data.description);
            formData.append("tags", data.tags);
            formData.append("role", data.role);
            formData.append("email", data.email);
            formData.append("firstName", data.firstName);
            formData.append("lastName", data.lastName);
            formData.append("therapyType", JSON.stringify(data.therapyType));
            formData.append("ageRange", JSON.stringify(data.ageRange));
            formData.append("videoid", data.videoid);
            formData.append("image", data.image);
            formData.append("materials", JSON.stringify(data.materials));
            formData.append("shortSummary", data.shortSummary);
            formData.append("DelCheck",data.DelCheck);
            formData.append("existingAsg",data.existingAsg);
            
            return axios
                .post(url + "/counselor/updateVideo", formData, { headers: headers })
                .then(res => {
                    //console.log(res)
                    if (res.data.status === 200) {
                        return true;
                    } else {
                        toastr.error(res.data.msg);
                    }
                })
                .catch(err => {
                    toastr.error("Something went wrong, Please try again");
                });
        } else {
            return axios
                .post(url + "/counselor/updateVideoWithoutthumbnail", { data }, { headers: headers })
                .then(res => {
                    //console.log(res,"res")
                    if (res.data.status === 200) {
                        return true;
                    } else {
                        toastr.error(res.data.msg);
                    }
                })
                .catch(err => {
                    toastr.error("Something went wrong, Please try again");
                });
        }


    };
}

export function uploadImage(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        const formData = new FormData();
        formData.append("profileImage", data.imageData);
        formData.append("role", data.role);
        return axios
            .post(url + "/user/uploadImage", formData, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg)
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function uploadImageForChild(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        const formData = new FormData();
        if (data.childId) {
            formData.append("profileImage", data.imageData);
            formData.append("parentId", data.parentId);
            formData.append("childId", data.childId);

        }
        else {
            formData.append("profileImage", data.imageData);
            formData.append("schoolId", data.schoolId);
            formData.append("studentId", data.studentId);
        }


        return axios
            .post(url + "/children/uploadImageForChild", formData, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg)
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getAllCounselorVideosDataForKid(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {

        return axios
            .post(url + "/children/getAllCounselorVideosDataForKid", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function editProfile(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/editProfile", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg)
                    return true
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function postComment(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/postComment", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return true
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function increaseViews(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/increaseViews", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.status
                } else if (res.data.status === 300) {
                    return res.data.status
                }
                else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getComments(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getComments", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function saveAuthorizationcodeForStripe(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/saveAuthorizationcodeForStripe", { data }, { headers: headers })
            .then(res => {
                ////console.log(res)
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                ////console.log(err)
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function checkConnectRequest(data, history){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers = {
        'Content-Type' : "application/json"
    }
    return function (dispatch){
        return axios.post(url+'/booking/checkConnectRequest',{data}, {headers: headers})
        .then(res => {
            if(res.data.status===200){
                if(res.data.data.connected){
                    toastr.error(res.data.msg);       
                }
                return res.data;    
            }
            if(res.data.status==301){
                toastr.error(res.data.msg);   
                return false;
            }
        })
        .catch(err=>{
            if(err){
                toastr.error(err);
                return false
            }
        })
    }
}

export function getStripeDetailForUser(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getStripeDetailForUser", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function selectVideoData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/selectVideoData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getAllCounselorVideoOnIdBasis(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getAllCounselorVideoOnIdBasis", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function CounselorVideos(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/counselor/getCounselorVideos", { data }, { headers: headers })
            .then(res => {
                
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return res.data.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getVideoForKids(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getVideoForKids", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return res.data.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function CounselorAllVideosforKid(data) {
    //console.log(data);
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/CounselorAllVideosforKid", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return res.data.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getEventsList(data) {
    var now = new Date();
    var expiryDate = new Date(data.expireTime)

    if (now > expiryDate) {
        axios.defaults.headers.common["Content-Type"] = `application/x-www-form-urlencoded`;
        return function (dispatch) {
            return axios
                .post(`https://oauth2.googleapis.com/token?client_id=${data.clientId}&client_secret=${data.clientSecret}&refresh_token=${data.refreshToken}&grant_type=refresh_token`)
                .then(res => {
                    if (res.status === 200) {
                        axios.defaults.headers.common["Authorization"] = localStorage.getItem(
                            "token"
                        );
                        var headers = {
                            "Content-Type": "application/json"
                        };
                        var now = new Date();
                        var expireData = new Date(now.getTime() + res.data.expires_in * 1000);
                        var newData = {
                            id: data.id,
                            accessToken: res.data.access_token,
                            expireTime: expireData
                        }

                        return axios
                            .post(url + "/booking/saveNewAccessToken", { newData }, { headers: headers })
                            .then(resp => {
                                if (resp.status === 200) {
                                    axios.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;
                                    axios.defaults.headers.common["Accept"] = `application/json`;
                                    return axios
                                        .get(`https://www.googleapis.com/calendar/v3/calendars/${data.googleCalendarId}/events?key=${data.apiKey}`)
                                        .then(res => {

                                            if (res.status === 200) {
                                                // dispatch(getCounselorVideos(res.data.data.data));
                                                return res;
                                            } else {
                                                // toastr.error(res.data.msg);
                                                return false
                                            }
                                        })
                                        .catch(err => {
                                            // toastr.error("Something went wrong, Please try again");
                                        });
                                } else {
                                    // toastr.error(res.data.msg);
                                    return false
                                }
                            })
                            .catch(err => {
                                toastr.error("Something went wrong, Please try again");
                            });

                        return res.data
                    } else {
                        // toastr.error(res.data.msg);
                        return false
                    }
                })
                .catch(err => {
                    toastr.error("Something went wrong, Please try again");
                });
        }
    } else {
        axios.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;
        axios.defaults.headers.common["Accept"] = `application/json`;
        return function (dispatch) {
            return axios
                .get(`https://www.googleapis.com/calendar/v3/calendars/${data.googleCalendarId}/events?key=${data.apiKey}`)
                .then(res => {

                    if (res.status === 200) {
                        // dispatch(getCounselorVideos(res.data.data.data));
                        return res;
                    } else {
                        // toastr.error(res.data.msg);
                        return false
                    }
                })
                .catch(err => {
                    // toastr.error("Something went wrong, Please try again");
                });
        }
    }
}

export function getAuthorizationToken(data) {
    axios.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded"
    return function (dispatch) {
        return axios
            .post(`https://oauth2.googleapis.com/token?code=${data.code}&client_id=${data.clientId}&client_secret=${data.clientSecret}&redirect_uri=${data.redirect_uri}&grant_type=authorization_code`)
            .then(res => {
                if (res.status === 200) {
                    return res.data
                } else {
                    return false
                }
            })
            .catch(err => {
                // toastr.error("Something went wrong, Please try again");
            });
    }
}

export function saveAuthorization(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/saveAuthorization", { data }, { headers: headers })
            .then(res => {

                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return res.data;
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function updateEvents(data) {
    var now = new Date();
    var expiryDate = new Date(data.expireTime)

    if (now > expiryDate) {
        axios.defaults.headers.common["Content-Type"] = `application/x-www-form-urlencoded`;
        return function (dispatch) {
            return axios
                .post(`https://oauth2.googleapis.com/token?client_id=${data.clientId}&client_secret=${data.clientSecret}&refresh_token=${data.refreshToken}&grant_type=refresh_token`)
                .then(res => {
                    if (res.status === 200) {
                        axios.defaults.headers.common["Authorization"] = localStorage.getItem(
                            "token"
                        );
                        var headers = {
                            "Content-Type": "application/json"
                        };
                        var now = new Date();
                        var expireData = new Date(now.getTime() + res.data.expires_in * 1000);
                        var newData = {
                            id: data.id,
                            accessToken: res.data.access_token,
                            expireTime: expireData
                        }

                        return axios
                            .post(url + "/booking/saveNewAccessToken", { newData }, { headers: headers })
                            .then(resp => {
                                if (resp.status === 200) {
                                    axios.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;
                                    axios.defaults.headers.common["Accept"] = `application/json`;
                                    axios.defaults.headers.common["Content-Type"] = `application/json`;
                                    var updatedata = {
                                        "calendarId": data.googleCalendarId,
                                        "eventId": data.eventId,
                                        "sendNotifications": true,
                                        "end": {
                                            "dateTime": data.endTime
                                        },
                                        "start": {
                                            "dateTime": data.startTime
                                        },
                                        "summary": data.description,
                                        "description": data.description,
                                        "location": data.location
                                    }
                                    return axios
                                        .patch(`https://www.googleapis.com/calendar/v3/calendars/${data.googleCalendarId}/events/${data.eventId}?key=${data.apiKey}`, updatedata)
                                        .then(res => {

                                            return res;
                                        })
                                        .catch(err => {
                                            // toastr.error("Something went wrong, Please try again");
                                        });
                                } else {
                                    // toastr.error(res.data.msg);
                                    return false
                                }
                            })
                            .catch(err => {
                                // toastr.error("Something went wrong, Please try again");
                            });

                        return res.data
                    } else {
                        // toastr.error(res.data.msg);
                        return false
                    }
                })
                .catch(err => {
                    // toastr.error("Something went wrong, Please try again");
                });
        }
    } else {
        axios.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;
        axios.defaults.headers.common["Accept"] = `application/json`;
        axios.defaults.headers.common["Content-Type"] = `application/json`;
        var updatedata = {
            "calendarId": data.googleCalendarId,
            "eventId": data.eventId,
            "sendNotifications": true,
            "end": {
                "dateTime": data.endTime
            },
            "start": {
                "dateTime": data.startTime
            },
            "summary": data.description,
            "description": data.description,
            "location": data.location
        }
        return function (dispatch) {
            return axios
                .patch(`https://www.googleapis.com/calendar/v3/calendars/${data.googleCalendarId}/events/${data.eventId}?key=${data.apiKey}`, updatedata)
                .then(res => {

                    return res;


                })
                .catch(err => {
                    // toastr.error("Something went wrong, Please try again");
                });
        }
    }

}

export function getCalenList(data) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.accesstoken}`;
    return function (dispatch) {
        return axios
            .get(`https://www.googleapis.com/calendar/v3/users/me/calendarList?key=${data.apiKey}`)
            .then(res => {
                if (res.status === 200) {

                    return res.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                // toastr.error("Something went wrong, Please try again");
            });
    }
}

export function addEvents(data) {
    var now = new Date();
    var expiryDate = new Date(data.expireTime)

    if (now > expiryDate) {
        axios.defaults.headers.common["Content-Type"] = `application/x-www-form-urlencoded`;
        return function (dispatch) {
            return axios
                .post(`https://oauth2.googleapis.com/token?client_id=${data.clientId}&client_secret=${data.clientSecret}&refresh_token=${data.refreshToken}&grant_type=refresh_token`)
                .then(res => {
                    if (res.status === 200) {
                        axios.defaults.headers.common["Authorization"] = localStorage.getItem(
                            "token"
                        );
                        var headers = {
                            "Content-Type": "application/json"
                        };
                        var now = new Date();
                        var expireData = new Date(now.getTime() + res.data.expires_in * 1000);
                        var newData = {
                            id: data.id,
                            accessToken: res.data.access_token,
                            expireTime: expireData
                        }

                        return axios
                            .post(url + "/booking/saveNewAccessToken", { newData }, { headers: headers })
                            .then(resp => {
                                if (resp.status === 200) {
                                    axios.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;
                                    // axios.defaults.headers.common["Accept"] = `application/json`;
                                    axios.defaults.headers.common["Content-Type"] = `application/json`;
                                    var updatedata = {
                                        "calendarId": data.googleCalendarId,
                                        // "resource": {
                                        "end": {
                                            "dateTime": moment(data.endTime).format(),
                                            // "timeZone": "America/Los_Angeles"
                                        },
                                        "start": {
                                            "dateTime": moment(data.startTime).format(),
                                            // "timeZone": "America/Los_Angeles"
                                        },
                                        "sendNotifications": true,
                                        "summary": data.description,
                                        "description": data.description,
                                        "location": data.location,
                                        "attendees": [{
                                            "email": data.email,
                                            "displayName": data.name,
                                            "responseStatus": "needsAction",
                                            "resource": false,
                                            "optional": false,
                                            "organizer": false,
                                        }]
                                        // }
                                    }
                                    return axios
                                        .post(`https://www.googleapis.com/calendar/v3/calendars/${data.googleCalendarId}/events?key=${data.apiKey}`, updatedata)
                                        .then(res => {
                                            return res;
                                        })
                                        .catch(err => {
                                            // toastr.error("Something went wrong, Please try again");
                                        });
                                } else {
                                    // toastr.error(res.data.msg);
                                    return false
                                }
                            })
                            .catch(err => {
                                // toastr.error("Something went wrong, Please try again");
                            });

                        return res.data
                    } else {
                        // toastr.error(res.data.msg);
                        return false
                    }
                })
                .catch(err => {
                    // toastr.error("Something went wrong, Please try again");
                });
        }
    } else {
        axios.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;
        // axios.defaults.headers.common["Accept"] = `application/json`;
        axios.defaults.headers.common["Content-Type"] = `application/json`;
        var updatedata = {
            "calendarId": data.googleCalendarId,
            // "resource": {
            "end": {
                "dateTime": moment(data.endTime).format(),
                // "timeZone": "America/Los_Angeles"
            },
            "start": {
                "dateTime": moment(data.startTime).format(),
                // "timeZone": "America/Los_Angeles"
            },
            "sendNotifications": true,
            "summary": data.description,
            "description": data.description,
            "location": data.location,
            "attendees": [{
                "email": data.email,
                "displayName": data.name,
                "responseStatus": "needsAction",
                "resource": false,
                "optional": false,
                "organizer": false,
            }]
            // }
        }
        return function (dispatch) {
            return axios
                .post(`https://www.googleapis.com/calendar/v3/calendars/${data.googleCalendarId}/events?key=${data.apiKey}`, updatedata)
                .then(res => {

                    return res;
                })
                .catch(err => {
                    // toastr.error("Something went wrong, Please try again");
                });
        }
    }

}

export function getSubscriptionActivity(data) {

    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/subscription/getSubscriptionActivity", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    ////console.log(res.data)

                    return res.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}



export function getMembership(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .get(url + "/booking/getMembership", { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    ////console.log(res.data.data)
                    return res.data.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function CounselorAllVideos(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/counselor/CounselorAllVideos", { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return res.data.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function allVideos(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/counselor/allVideos", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return res.data.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getKidsSelectedEmotionFilter(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getKidsSelectedEmotionFilter", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return res.data.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getKidsSelectedEmotionFilterCount(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getKidsSelectedEmotionFilterCount", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return res.data.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getKidsSelectedEmotion(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getKidsSelectedEmotion", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return res.data.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function saveKidsEmotion(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/saveKidsEmotion", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return res.data.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function likeCommentData(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/likeCommentData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return true
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function likeVideoData(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/likeVideoData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // dispatch(getCounselorVideos(res.data.data.data));
                    return true
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function saveBooking(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/counselor/saveBooking", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return true
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function removeToken(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/removeToken", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return true
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getElementToken() {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getElementToken", { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getAllCounselorData() {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/getAllCounselorData", { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function uploadFileForchat(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        const formData = new FormData();
        formData.append("chatFile", data);
        return axios
            .post(url + "/chat/uploadFileForchat", formData, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}




// export function updateLatestMsg (data) {
//     axios.defaults.headers.common["Authorization"] = localStorage.getItem(
//         "token"
//     );
//     var headers = {
//         "Content-Type": "application/json"
//     };
//     return function (dispatch) {

//         return axios
//             .post(url + "/chat/updateLatestMsg", {data}, { headers: headers })
//             .then(res => {
//                 if (res.data.status === 200) {
//                     // toastr.success(res.data.msg)
//                     return res.data.data;
//                 } else {
//                     toastr.error(res.data.msg);
//                 }
//             })
//             .catch(err => {
//                 toastr.error("Something went wrong, Please try again");
//             });
//     };
// }

export function fetchmessagelistLatest(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {

        return axios
            .post(url + "/chat/fetchmessagelistLatestupdateLatestMsg", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function fetchmessagelist(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {

        return axios
            .post(url + "/chat/fetchmessagelist", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function uploadImageForchat(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        const formData = new FormData();
        formData.append("chatFile", data);
        return axios
            .post(url + "/chat/uploadImageForchat", formData, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function uploadThumbnailFunction(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        const formData = new FormData();
        formData.append("thumbnailFile", data);
        return axios
            .post(url + "/counselor/uploadThumbnailFunction", formData, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function uploadRecordedForCounselor(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        const formData = new FormData();
        formData.append("file", data.filename, data.filename.name);
        formData.append("counselorId", data.id);
        formData.append("firstName", data.firstName);
        formData.append("lastName", data.lastName);
        formData.append("email", data.email);
        formData.append("image", data.image);

        return axios
            .post(url + "/counselor/uploadRecordedForCounselor", formData, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function uploadRecordedForKid(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        const formData = new FormData();
        formData.append("file", data.filename, data.filename.name);
        formData.append("parentId", data.parentId);
        formData.append("childId", data.childId);
        formData.append("parentName", data.parentName);
        formData.append("childName", data.childName);
        formData.append("image", data.image);
        formData.append("parentEmail", data.parentEmail);
        formData.append("councellorId", data.councellorId);

        return axios
            .post(url + "/children/uploadRecordedForKid", formData, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function setFlag(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/setFlag", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getMediaDetails(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/chat/getMediaDetails", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function listEvents(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/listEvents", { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function saveAccesstoken(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/saveAccesstoken", { data }, { headers: headers })
            .then(res => {
                if (res.status === 200) {
                    return res;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getAccessToken(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getAccessToken", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getCounselorData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/counselor/getCounselorData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getChildrenData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getChildrenData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getStudentData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getStudentData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getStudentDataAdmin(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getStudentDataAdmin", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getStudentProgressDataAdmin(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getStudentProgressDataAdmin", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    return false;
                   // toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getStudentProgressDataSchool(data, history) {
    

    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getStudentProgressDataSchool ", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    return false;
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function getStudentProgressDataDistrict(data, history) {
     
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/getStudentProgressDataDistrict ", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function getSchoolData(data, history) {
    //console.log(data)
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios

            .post(url + "/user/getSchoolData", { data }, { headers: headers })
            .then(res => {
                //console.log(res)
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getVideoDetails(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/counselor/getVideoDetails", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function deleteVideoDetails(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/counselor/deleteVideoDetails", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function deleteKidVideo(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/children/deleteKidVideo", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function updatePassword(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/updatePassword", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function updateSubscriptionActivityDistrictBooking(data, history) {

    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/subscription/updateSubscriptionActivityDistrictBooking", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // //console.log(res.data)

                    return res.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }

}


export function updateSubscriptionActivity(data, history) {
    // //console.log(data)
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/subscription/updateSubscriptionActivity", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // //console.log(res.data)

                    return res.data
                } else {
                    // toastr.error(res.data.msg);
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function cancelAccount(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/cancelAccount", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    localStorage.removeItem('token')
                    dispatch(userLoginDetails(''));
                    history.push({ pathname: "/" });
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function deleteChildData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/deleteChildData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function deleteStudentData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/deleteStudentData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function deleteSchoolData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/deleteSchoolData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}




export function deleteAdminDistrictData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/deleteAdminDistrictData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function deleteAdminSchoolData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/deleteAdminSchoolData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function updateChildInfo(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/updateChildInfo", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function updateStudentInfo(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/updateStudentInfo", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


//updateStudentInfoAdmin

export function updateStudentInfoAdmin(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/updateStudentInfoAdmin", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function updateSchoolInfo(data, history) {
    //console.log(data)
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/updateSchoolInfo", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                //console.log(err)
                toastr.error("Something went wrong, Please try again");
            });
    };
}




export function updateDistrictAdminInfo(data, history) {
    //console.log(data)
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/updateDistrictAdminInfo", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                //console.log(err)
                toastr.error("Something went wrong, Please try again");
            });
    };
}



export function addCardData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/card/addCardData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function validateCardData(data, history){
    
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers = {
        'Content-Type' : "application/json"
    }
    return function (dispatch){
        return axios
            .post(url + "/card/validateCardData",  { data }, { headers: headers })
            .then(res => {
                return res.data;
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }

}

export function getCardData() {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/card/getCardData", { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    return false;
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function deleteCard(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/card/deleteCard", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function updateCard(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/card/updateCard", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function addChild(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/addChild", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function addStudent(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/addStudent", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg); 
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function addStudentAdmin(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/addStudentAdmin", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}
//School add

export function addSchool(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/addSchool", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}


export function addAdmin(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/addAdmin", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg);
                    return true;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}




export function getCounselorDataSelected(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/counselor/getCounselorDataSelected", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    return false;
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}

export function getSchoolDataSelected(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/getSchoolDataSelected", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    return false;
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}



export function connectToStripeExpress(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/connectToStripeExpress", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getAccesstokenfortwillio(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/videosession/getAccesstokenfortwillio", { data }, { headers: headers })
            .then(res => {
                // //console.log(res)
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function savetimeSlot(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/savetimeSlot", { data }, { headers: headers })
            .then(res => {
                //console.log(res,"response from server")
                if (res.data.status === 200) {
                    // //console.log(res.data.data)
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getDataofslot(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getDataofslot", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getBookedEvents(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getBookedEvents", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                //console.log(err)
                //toastr.error("Something went wrong, Please try again");
            });
    }
}

export function createEvent(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/createEvent", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data
                } else {
                    toastr.error(res.data.msg);   
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getconfirmedEvent(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getconfirmedEvent", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function createnote(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/createnote", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return true
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function callRatingDetails(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/callRatingDetails", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    history.push({ pathname: "/" });
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getcounselordetails(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getcounselordetails", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getallselecteddates(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getallselecteddates", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // //console.log(res.data.data)
                    return res.data.data

                } else {
                    return false
                }
            })
            .catch(err => {
                //toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getselectedSlot(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getselectedSlot", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    //  //console.log(res.data.data)
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function fetchalldates(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/fetchalldates", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function fixEvent(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/fixEvent", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function sendRescheduleLink(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/sendRescheduleLink", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return true
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getallsessionlist(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getallsessionlist", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getCounselorDetailsformessage(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getCounselorDetailsformessage", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getAllsesssionListOnUser(data, history) {

    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getAllsesssionListOnUser", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}
//note

export function getNotesOnUser(data, history) {

    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/getNotesOnUser", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function againReschedulelink(data, history) {
    //console.log(data)
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/againReschedulelink", { data }, { headers: headers })
            .then(res => {

                if (res.data.status === 200) {

                    history.push({ pathname: `/counselor-search` });

                    return true
                } else {
                    toastr.error(res.data.msg);
                    history.push({ pathname: "/" });
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function checkloginforcounselor(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/booking/checkloginforcounselor", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.setItem("token", res.data.data.token);
                    localStorage.setItem("usertype", "user");
                    localStorage.setItem("isChildLogin", false);
                    localStorage.setItem("calendar", "calendar");
                    localStorage.setItem("messages", "messages");
                    localStorage.setItem("documents", "documents");
                    localStorage.setItem("revenue", "revenue");
                    dispatch(userLoginDetails(res.data.data.userdetailsdata));
                    history.push({ pathname: "/view-calender" });
                    return true
                } else {
                    toastr.error(res.data.msg);
                    history.push({ pathname: "/" });
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}


//payout

export function getAllPayoutRequest(data) {
    //console.log(data,"data from frontend")
    return function (dispatch) {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem(
            "token"
        );
        var headers = {
            "Content-Type": "application/json"
        };
        return axios
            .post(url + "/user/getAllPayoutRequest", { data }, { headers: headers })
            .then(res => {
                ////console.log(res,"payout response")
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg)
                    return res.data.data;
                } else {
                    return false;
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}
//admin update data

export function updateAdminData(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/paymentInfo/updateAdminData", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });

    };
}



export function getSubDistrictBooking(data) {

    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/subscription/getSubDistrictBooking ", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data
                } else {
                    return false
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function generateVideoLog(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
    var headers = { "Content-Type": "application/json" };
    return function (dispatch) {
        return axios
            .post(url + "/user/videolog", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data
                } else {
                    return false
                }
            }).catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function updateTimeStamp(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
    var headers = { "Content-Type": "application/json" };
    return function (dispatch) {
        return axios
            .post(url + "/user/update-time-stamp", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data
                } else {
                    return false
                }
            }).catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function endVideoLog(data) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
    var headers = { "Content-Type": "application/json" };
    return function (dispatch) {
        return axios
            .post(url + "/user/end-videolog", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data
                } else {
                    return false
                }
            }).catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    }
}

export function getConsentInfo(data){
    axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
    var headers = { "Content-Type": "application/json" };
    return function (dispatch) {
        return axios
        .post(url + '/user/getConsentInfo',{data}, headers)
        .then(res=>{
            
            if(res.data.status===200){
                return res.data;
            }else{
                return { invalid : true, msg: res.data.msg}
            }
        }).catch(err=>{
            return { invalid : true}
        });
    }
}

export function updateWelcomeStatus(data){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers= {"Content-Type": "application/json"};

    return function (dispatch){
        return axios.post(url + '/user/updateWelcomeStatus',{data}, headers);
    }
}

export function verifyForgetToken(token){
    var headers= {"Content-Type": "application/json"};
    return function (dispatch){
        return axios.post(url + '/user/verifyForgetToken',{token}, headers).then(resp=>{
            if(resp.data.status===200){
                return true;
            }else{
                return false;
            }
        }).catch(err=>{
            return false;
        });
    }
}

export function getRemainingBooking(data){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers= {"Content-Type": "application/json"};
     
    return function (dispatch){
        return axios.post(url + '/subscription/getRemainingBooking',{data}, headers).then(resp=>{
            if(resp.data.status===200){
                return resp.data;
            }else{
                return false;
            }
        }).catch(err=>{
            return false;
        });
    }
}

export function getSchoolPlans(){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers= {"Content-Type": "application/json"};   
    return function (dispatch){
        return axios.post(url + '/user/getSchoolPlans',{}, headers).then(resp=>{
            if(resp.data.status===200){
                return resp.data;
            }else{
                return false;
            }
        }).catch(err=>{
            return false;
        });
    }
}

export function checkSchoolPromoCode(data){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers= {"Content-Type": "application/json"};   
    return function (dispatch){
        return axios.post(url + '/user/checkSchoolPromoCode',{data}, headers).then(resp=>{
            return resp.data;
        }).catch(err=>{
            return false;
        });
    }    
}

export function checkParentPromoCode(data){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers= {"Content-Type": "application/json"};   
    return function (dispatch){
        return axios.post(url + '/user/checkParentPromoCode',{data}, headers).then(resp=>{
            return resp.data;
        }).catch(err=>{
            return false;
        });
    }    
}

export function getParentPlan(data){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers= {"Content-Type": "application/json"};   
    return function (dispatch){
        return axios.post(url + '/user/getParentPlan',{data}, headers).then(resp=>{
            return resp.data;
        }).catch(err=>{
            return false;
        });
    }       
}


export function createParentSubscription(data){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers= {"Content-Type": "application/json"};   
    return function (dispatch){
        return axios.post(url + '/user/createParentSubscription',{data}, headers).then(resp=>{
            return resp.data;
        }).catch(err=>{
            return false;
        });
    }
}

export function createSchooolSubscription(data){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers= {"Content-Type": "application/json"};   
    return function (dispatch){
        return axios.post(url + '/user/createSchooolSubscription',{data}, headers).then(resp=>{
            return resp.data;
        }).catch(err=>{
            return false;
        });
    }
}

export function getTherapyTags(data){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers= {"Content-Type": "application/json"};   
    return function (dispatch){
        return axios.post(url + '/user/getTherapyTags',{data}, headers).then(resp=>{
            return resp.data;
        }).catch(err=>{
            return false;
        });
    }       
}

export function updateSubtitleTracking(data){
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
    var headers= {"Content-Type": "application/json"};   
    return function (dispatch){
        return axios.post(url + '/user/updateSubtitleTracking',{data}, headers).then(resp=>{
            return resp.data;
        }).catch(err=>{
            return false;
        });
    }       
}
export function loginIndSchoolAction(regToken){
    var headers= {"Content-Type": "application/json"};
    return function (dispatch){
        return axios.post(url + '/user/loginIndSchool',{regToken}, headers).then(resp=>{
            if(resp.data){
                return resp.data;
            }else{
                return false;
            }
        }).catch(err=>{
            return false;
        });
    }    
}

export function SendInvite(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/SendInvite", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    // toastr.success(res.data.msg);
                    console.log(res.data);
                    return res.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}
export function StudentResendEmail(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/StudentResendEmail", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    toastr.success("An email has been re-sent successfully.");
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}
export function getInviteStuData(data, history) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "token"
    );
    var headers = {
        "Content-Type": "application/json"
    };
    return function (dispatch) {
        return axios
            .post(url + "/user/getInvitationList", { data }, { headers: headers })
            .then(res => {
                if (res.data.status === 200) {
                    return res.data.data;
                } else {
                    toastr.error(res.data.msg);
                }
            })
            .catch(err => {
                toastr.error("Something went wrong, Please try again");
            });
    };
}
export function getInvitedVideo(videoId){
    var headers= {"Content-Type": "application/json"};
    return function (dispatch){
        return axios.post(url + '/user/getInvitedVideo',{videoId}, headers).then(resp=>{
            if(resp.data){
                return resp.data;
            }else{
                return false;
            }
        }).catch(err=>{
            return false;
        });
    }    
}
export function getMediumBlogs(){
    var headers= {"Content-Type": "application/json"};
    return function (dispatch){
        return axios.post(url + '/user/getMediumBlogs',{}, headers).then(resp=>{
            if(resp.data){
                return resp.data;
            }else{
                return false;
            }
        }).catch(err=>{
            return false;
        });
    }    
}