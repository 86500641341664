import React, { Component } from "react";
import {
   FormBuilder,
   FieldGroup,
   FieldControl,
   Validators
} from "react-reactive-form";
import { connect } from "react-redux";
import _ from "lodash";
import toastr from "toastr";
import axios from 'axios'
import url from "../../config/config";
import Header from '../header/Header'
import Footer from '../footer/Footer'
import {
    getUserData, getBookedEvents, getAllsesssionListOnUser, getConsentInfo, LoginAction
} from "../../action/user_login_action";
import StateData from '../../state.json';
import moment from "moment";
import {Redirect} from 'react-router-dom'
 

class ConsentForm extends Component {
   constructor(props) {
        super(props);
        this.state = {
            consentData:'',
            logOnBtnStatus: true,
            consentFormConfirmed : false,
            consentFormStatus : false,
            autoLogin:false
        };

        

        this.ConsentData = FormBuilder.group({
            studentName: ["", [Validators.required]],
            guardianEmail: ["", [Validators.required, Validators.email]],
            consentForm:["", [Validators.required]],
        });

   }
 
    async  componentDidMount() {

        var loggedInUser = await this.props.dispatch(getUserData());        
       
        var consentToken = this.props.match.params.token;
        if(this.props.location.search==='?auto=1'){
            var data = { token: consentToken, role: 7};
            let result = await this.props.dispatch(LoginAction(data, this.props.history));
            return;
        }
        var retrieveConsentInfo = await this.props.dispatch(getConsentInfo({token : consentToken}));
        if(retrieveConsentInfo.data.consentStatus=="true"){            
            var data = { token: consentToken, role: 7};
            let result = await this.props.dispatch(LoginAction(data, this.props.history));
            return;
        }
        this.setState({
            autoLogin:true
        });        
        if(retrieveConsentInfo.status==200){
            this.ConsentData.patchValue({
                studentName: retrieveConsentInfo.data.studentName,
                guardianEmail: retrieveConsentInfo.data.guardianEmail,
            })
            
            if(retrieveConsentInfo.data.confirmed){
                this.setState({
                    consentFormConfirmed : true ,
                    logOnBtnStatus: false,
                    consentFormStatus: true
                })
                
                if(retrieveConsentInfo.data.consentStatus=="true"){
                    this.ConsentData.patchValue({
                        consentForm: true,
                    })
                }else{
                    this.ConsentData.patchValue({
                        consentForm: false,
                    })  
                }
            }
              
        }else{
           // toastr.error(retrieveConsentInfo.msg);
           this.props.history.push('/')  
        }
         
    }

 
    consentChange= (e) => {
        if (e.target.value) {
            this.setState({
                consentData: e.target.value
            })
        }
        else {
            toastr.error("Please select an option.")
        }
    }
 
    consentSubmit= async (e)=>{
        e.preventDefault();
        if(this.ConsentData.invalid){
            toastr.error("Please fill all fields")
        }else{
            let data={
                studentName:this.ConsentData.value.studentName.toLowerCase(),
                guardianEmail:this.ConsentData.value.guardianEmail.toLowerCase(),
                consentForm:this.state.consentData,
            }
            var headers = {
                "Content-Type": "application/json" 
            }
                        
            axios.post(url+'/consent',data,{headers : headers}).then(res=>{
                if(res.data.status === 301){
                    toastr.error(res.data.msg)
                }else{
                     
                    this.setState({
                        logOnBtnStatus: false,
                        consentFormConfirmed : true,
                        consentFormStatus: true
                    })
                    toastr.success(res.data.msg)
                }
            })
            .catch(err=>{
                toastr.error(err.data.msg);
            })
        }
    }
    
    logOnBtn = async (e) => {
        var token = this.props.match.params.token;  
        var data = { token: token, role: 7};
          
        let result = await this.props.dispatch(LoginAction(data, this.props.history));
         
    }

   render() {
      if(this.state.autoLogin === false){
        return null;
      } else {
      return (
        
        <>
        <Header/>
        <div className="page-title text-center">
            <span className="font-fredoka">Consent Form

            </span>

        </div>
        <div class="py-3 container">
            <div class="infor-box text-center px-4">
                 <p class="line-h">Please fill this form to approve or decline your consent</p>
            </div>
        </div> 

        <div className=" container" >
                <div className="col-md-12 pt-4" >

                    <FieldGroup
                        control={this.ConsentData}
                        strict={false}
                        
                        render={({ get, invalid }) => (
                            <form onSubmit={this.consentSubmit}>
                                <div className="row">
 
                                    <FieldControl
                                            name="guardianEmail"
                                            render={({ handler, touched, hasError, meta }) => (
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="">Guardian's Email <sup className="required">*</sup></label><info>(Please enter the email address we sent your account details to)</info>
                                                        <input readOnly type="text" className="form-control"  {...handler()} />
                                                        <span className="pro-error" style={{ color: "red" }}>
                                                            {touched &&
                                                                (
                                                                    (hasError("required") && `Required`) ||
                                                                    (hasError("email") && `Please enter valid email address`) 
                                                                )
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        />      


                                        <FieldControl
                                        name="studentName"
                                        render={({ handler, touched, hasError, meta }) => (
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="">Student's Name <sup className="required">*</sup></label>
                                                    <input readOnly type="text" className="form-control"  {...handler()} />
                                                    <span className="pro-error" style={{ color: "red" }}>
                                                        {touched &&
                                                            (
                                                                (hasError("required") && `Required`) 
                                                            )
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    />    

                                    <FieldControl
                                        name="consentForm"
                                        render={({ handler, touched, hasError, meta }) => (
                                            <div className="col-md-12">

                                            <div className="form-group">
                                                <label for="">Consent Status <sup className="required">*</sup></label>
                                                <select name="" id=""  {...handler()}
                                                    onChangeCapture={this.consentChange} className="form-control custom-select"
                                                    disabled={this.state.consentFormStatus}    
                                                    >
                                                    <option value="">Pending Consent</option>
                                                    <option value="true">Approve Consent</option>
                                                    <option value="false">Decline Consent</option>
                                                    
                                                </select>
                                            </div>
                                            </div>

                                        )}
                                    />
                                </div>
                                <div className="divider"></div>
                                <ul class="nav">
                                  {!this.state.consentFormConfirmed &&
                                    <>
                                        <li class="nav-item consent_form_li">
                                            <button type="submit" disabled={invalid} className="btn btn-primary font-fredoka btn-lg submit_consent">Submit Consent</button>
                                        </li>
                                    </>
                                  }
                                  <li class="nav-item">
                                    <button onClick={(e)=>this.logOnBtn(e)} type="button" disabled={this.state.logOnBtnStatus} className="btn btn-primary font-fredoka btn-lg logon_consent">Log On</button>
                                  </li>
                                </ul>
                                

                                
                            </form>
                        )}
                    />
                
                    </div>
            </div>
        </>
      
        
      ); }
   }
}

function mapStateToProps(state, ownProps) {
   return {
      loggedInUser: state.detailR.loggedInUser
   };
}

export default connect(mapStateToProps)(ConsentForm);
