import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import configStore from "./store/configureStore";



const videoJsOptions = {
  controls: true,
  bigPlayButton: false,
  width:750,
  height: 350,
  fluid: true,
  plugins: {
    /*
    // wavesurfer section is only needed when recording audio-only
    wavesurfer: {
        backend: 'WebAudio',
        waveColor: '#36393b',
        progressColor: 'black',
        debug: true,
        cursorWidth: 1,
        msDisplayMax: 20,
        hideScrollbar: true,
        displayMilliseconds: true,
        plugins: [
            // enable microphone plugin
            WaveSurfer.microphone.create({
                bufferSize: 4096,
                numberOfInputChannels: 1,
                numberOfOutputChannels: 1,
                constraints: {
                    video: false,
                    audio: true
                }
            })
        ]
    },
    */
    record: {
      audio: true,
      video: {
        // video media constraints: set resolution of camera
        width: 750,
        height: 380
    },
    // dimensions of captured video frames
    frameWidth: 750,
    frameHeight: 380,
      maxLength: 10000,
      debug: true,
      videoMimeType: "video/webm"
    }
  }
};
const store = configStore();
ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
      <BrowserRouter>
      <App {...videoJsOptions}/>
      </BrowserRouter>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA