import { GET_COUNSELOR_VIDEOS } from "../action/actionConstant/action_constant";
const initialState = [];
export default function videoReducer(state = initialState, action) {
  switch (action.type) {
      case GET_COUNSELOR_VIDEOS:
        return {
          counselorVideos: action.Payload
        }

    default:
      return { ...state };
  }
}
