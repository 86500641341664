import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch, withRouter } from "react-router-dom";
import './App.scss';

import Loader from 'react-loader-spinner'
import consentForm from './components/consentForm/consentForm';

import ReactGA from 'react-ga';

const loading = () => (
  <div className="user-db-loader">
    <Loader
      type="ThreeDots"
      color="#00BFFF"
      height={100}
      width={100}
    />
  </div>
);

const Dashboard = React.lazy(() =>
  import("./components/Dashboard/Dashboard")
);

const AccountSettingsPage = React.lazy(() =>
  import("./components/accountSetting/accountsetting")
);
const CounselorPage = React.lazy(() =>
  import("./components/counselorPage/counselor")
);
const ChildrenList = React.lazy(() =>
  import("./components/ChildrenList/childrenlist")
);

const ParentListStudent = React.lazy(() =>
  import("./components/ChildrenList/parentStudentList")
);


const CounselorSearch = React.lazy(() =>
  import("./components/counselorSearch/counselorSearch")
);

const CounselorSearchDistrict = React.lazy(() =>
  import("./components/counselorSearch/counselorSearchDistrict")
);

const VideoListing = React.lazy(() =>
  import("./components/videoListing/videoListing")
);
const VideoUpload = React.lazy(() =>
  import("./components/videoListing/videoUploadpage")
);

const AccountVerify = React.lazy(() =>
  import("./components/accountVerified/AccountVerified")
);
const ForgotPassword = React.lazy(() =>
  import("./components/forgetPassword/ForgotPassword")
);
const HomePage = React.lazy(() =>
  import("./components/homepage/homepage")
);

const AnimationSeries = React.lazy(() =>
  import("./components/animationpage/animationSeries")
);
const BlogList = React.lazy(() =>
  import("./components/blog/blogList")
);
const Invite = React.lazy(() =>
  import("./components/invite/Invite")
);
const StudentInvite = React.lazy(() =>
  import("./components/invite/studentWatch")
);


const Partners = React.lazy(() =>
  import("./components/partner/partner")
);
const AgainReschedule = React.lazy(() =>
  import("./components/again/againreschedule")
);
const InternalServer = React.lazy(() =>
  import("./components/page505/InternalServer")
);
const ResetPassword = React.lazy(() =>
  import("./components/resetPassword/ResetPassword")
);

const ResetPasswordStudent = React.lazy(() =>
  import("./components/resetPassword/ResetPasswordStudent")
);

const ChildLoginParent = React.lazy(() =>
  import("./components/childLoginParent/ChildLoginParent")
);
const ThankYou = React.lazy(() =>
  import("./components/thankyou/thankyou")
);
const Profile = React.lazy(() =>
  import("./components/Profile/profile")
);
const EditProfile = React.lazy(() =>
  import("./components/Profile/editProfile")
);
const WatchVideos = React.lazy(() =>
  import("./components/watchVideo/watchVideo")
);
const BookedCalender = React.lazy(() =>
  import("./components/calender/calender")
);
const MessageBoard = React.lazy(() =>
  import("./components/chatPage/message")
);
const VideoRecord = React.lazy(() =>
  import("./components/videorecord/videoRecord")
);
const CounselorBookPage = React.lazy(() =>
  import("./components/counselorBook/counselorBookPage")
);


const CounselorBookDistrict = React.lazy(() =>
  import("./components/counselorBook/counselorBookDistrict")
);


const CounselorProfile = React.lazy(() =>
  import("./components/counselorProfile/counselorProfile")
);
const CounselorCalendar = React.lazy(() =>
  import("./components/counselorCalendar/counselorCalendar")
);
const ViewProgress = React.lazy(() =>
  import("./components/viewProgress/viewProgress")
);
const ViewProgressStudent = React.lazy(() =>
  import("./components/viewProgress/viewProgressStudent")
);


const StudentProgressDistrictView = React.lazy(() =>
  import("./components/viewProgress/studentProgressDistrictView")
);



const DocumentsSection = React.lazy(() =>
  import("./components/Documents/documents")
);
const Revenue = React.lazy(() =>
  import("./components/Revenue/revenue")
);
const PointsBreakdown = React.lazy(() =>
  import("./components/points/point")
);
const Privacy = React.lazy(() =>
  import("./components/privacy/privacy")
);
const Terms = React.lazy(() =>
  import("./components/terms/terms")
);


const Temporary = React.lazy(() =>
  import("./components/temporary/temporary")
);
const UploadVideoForKids = React.lazy(() =>
  import("./components/uploadVideoForKids/uploadVideoForKids")
);
const Videosession = React.lazy(() =>
  import("./components/videosession/videosession")
);
const RateCall = React.lazy(() =>
  import("./components/ratecall/ratecall")
);
const ConfirmedEvent = React.lazy(() =>
  import("./components/confirmedEvent/confirmedEvent")
);
const School = React.lazy(() =>
  import("./components/school/school")
);
const SessionList = React.lazy(() =>
  import("./components/sessionlist/sessionlist")
);
const FeedbackPage = React.lazy(() =>
  import("./components/feedbackpage/feedbackpage")
);
const PayoutForm = React.lazy(() =>
  import("./components/Revenue/payoutForm")
);

const Commercial = React.lazy(() =>
  import("./components/commercials/commercial")
);

const Documents = React.lazy(() =>
  import("./components/Documents/documents")
);

const ConsentForm = React.lazy(() =>
  import("./components/consentForm/consentForm")
);

const CounselorBookSchool = React.lazy(() =>
  import("./components/counselorBook/counselorBookSchool")
);
const BookPackage = React.lazy(() =>
  import("./components/bookPackage/bookPackage")
);

const CounselorBookSchoolIndividual = React.lazy(() =>
  import("./components/counselorBook/counselorBookSchoolIndividual")
);

const StudentPasswordChange = React.lazy(() =>
  import("./components/studentPasswordChange/studentPasswordChange")
);

const SchoolAdmins = React.lazy(() =>
  import("./components/schoolAdmins/schoolAdmins")
);
const DistrictAdmins = React.lazy(() =>
  import("./components/schoolAdmins/DistrictAdmins")
);

const CounselorBookAdminSchool = React.lazy(() =>
  import("./components/counselorBook/counselorBookAdminSchool")
)

const CounselorProfileStaff = React.lazy(() =>
  import("./components/counselorProfile/counselorProfileStaff")
)

const HowItWorks = React.lazy(() =>
  import("./components/howItWorks/howItWorks")
);

const ViewSchoolDetails = React.lazy(() =>
  import("./components/viewSchoolDetails/viewSchoolDetails")
);

const BookingHistory = React.lazy(() =>
  import("./components/calender/bookingHistory")
);

const LoginUserPrompt = React.lazy(() =>
  import("./components/loginUserPromt/loginUserPrompt")
);

const CounselorProfileDistrict = React.lazy(() => import("./components/counselorProfile/counselorProfileDistrict"))

const PageNotFound = React.lazy(()=> import('./components/PageNotFound/PageNotFound'))


class App extends Component {
  componentDidMount() {

    ReactGA.initialize('UA-162775744-1');

    ReactGA.pageview(window.location.pathname + window.location.search);

    window.scrollTo(0, 0)

    window.Intercom("boot", {
      app_id: "udip9gba",

    });

    window.Intercom("update");

  }
  render() {
    return (
      <React.Suspense fallback={loading()}>
        <BrowserRouter>
          <Switch>

            <Route
              exact
              path="/"
              name="Home"
              render={props => <HomePage {...props} />}
            />

            <Route
              exact
              path="/animation-series"
              name="Animation Page"
              render={props => <AnimationSeries {...props} />}
            />

          {/* <Route
              exact
              path="/blog"
              name="Animation Page"
              render={props => <BlogList {...props} />}
            /> */}
            <Route exact path="/blog" component={() => { 
              window.location.href = 'https://blog.guardianlane.com/'; 
              return null;
              }} />
              
            <Route 
              exact
              path="/invite"
              name="Invite Student"
              render={props => <Invite {...props} />}
            />

            <Route 
              exact
              path="/student-watch/:videoId"
              name="Student Watch"
              render={props => <StudentInvite {...props} />}
            />

            {/* <Route
              exact
              path="/register"
              name="Register Page"
              render={props => <SignUp {...props} />}
            /> */}
            <Route
              exact
              path="/again-reshedule/:token"
              name="Page 404"
              render={props => <AgainReschedule {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={props => <InternalServer {...props} />}
            />
            <Route
              path="/forgetPassword"
              name=" Forgot Password"
              render={props => <ForgotPassword {...props} />}
            />
            <Route
              path="/dashboard/:token"
              name="User Dashboard"
              render={props => <Dashboard {...props} />}
            />
            
            <Route
              path="/dashboard"
              name="User Dashboard"
              render={props => <Dashboard {...props} />}
            />
            <Route
              path="/reset-password/:forgetoken"
              name="ResetForgetPassword"
              render={props => <ResetPassword {...props} />}
            />
            <Route
              path="/reset-password-student/:forgetoken"
              name="ResetForgetPasswordStudent"
              render={props => <ResetPasswordStudent {...props} />}
            />
            <Route
              path="/edit-upload/:editId"
              name="Edit video"
              render={props => <VideoUpload {...props} />}
            />
            <Route
              path="/upload-video"
              name="VideoListing"
              render={props => <VideoListing {...props} />}
            />
            <Route
              exact path="/account-verfiy/:token"
              name="VerifyToken"
              render={props => <AccountVerify {...props} />}
            />
            <Route
              path="/email-verification-page"
              name="Email Verification Page"
              render={props => <ThankYou {...props} />}
            />
            <Route
              path="/profile"
              name="View Profile"
              render={props => <Profile {...props} />}
            />
            <Route
              path="/edit-profile"
              name="Edit Profile"
              render={props => <EditProfile {...props} />}
            />
            <Route
              path="/watch-video/:id/:slug"
              name="Watch Videos"
              render={props => <WatchVideos {...props} />}
            />
            <Route
              path="/view-calender/:id"
              name="Book session"
              render={props => <BookedCalender {...props} />}
            />
            <Route
              path="/view-calender"
              name="Book session"
              render={props => <BookedCalender {...props} />}
            />
            <Route
              path="/view-progress/:id"
              name="View Progress"
              render={props => <ViewProgress {...props} />}
            />
            <Route
              path="/view-progress-student/:id"
              name="View Progress Student"
              render={props => <ViewProgressStudent {...props} />}
            />

            <Route
              path="/view-school-details/:id"
              name="View Progress Student"
              render={props => <ViewSchoolDetails {...props} />}
            />


            <Route
              path="/message-board"
              name="Chat Portal"
              render={props => <MessageBoard {...props} />}
            />
            <Route
              //path="/video-record/:id"
              path="/video-record"
              name="Record A Video"
              render={props => <VideoRecord {...this.props} {...props} />}
            />
            <Route
              path="/counselor-page"
              name="View counselor page"
              render={props => <CounselorPage {...props} />}
            />
            <Route
              path="/counselor-search"
              name="Search counselor"
              render={props => <CounselorSearch {...props} />}
            />

            <Route
              path="/counselor-search-district"
              name="Search counselor"
              render={props => <CounselorSearchDistrict {...props} />}
            />

            <Route
              path="/children-list"
              name="Children List"
              render={props => <ChildrenList {...props} />}
            />

            <Route
              path="/student-list-parent"
              name="Children List"
              render={props => <ParentListStudent {...props} />}
            />


            <Route
              path="/book-counselor/:id"
              name="Book counselor"
              render={props => <CounselorBookPage {...props} />}
            />
            <Route
              path="/book-counselor-school/:id"
              name="Book counselor School"
              render={props => <CounselorBookSchool {...props} />}
            />


            <Route
              path="/book-counselor-district/:id"
              name="Book counselor District"
              render={props => <CounselorBookDistrict {...props} />}
            />



            {/* <Route
              path="/book-counselor-school-staff"
              name="Book counselor Staff School"
              render={props => <CounselorBookAdminSchool {...props} />}
            /> */}

            <Route
              path="/book-counselor-school-staff/:id"
              name="Book counselor Staff School"
              render={props => <CounselorBookAdminSchool {...props} />}
            />

            <Route
              path="/book-counselor-school-individual"
              name="Book counselor School Individual"
              render={props => <CounselorBookSchoolIndividual {...props} />}
            />

            <Route
              path="/book-package"
              name="Book Package"
              render={props => <BookPackage {...props} />}
            />

            <Route
              path="/account-setting"
              name="Account Settings"
              render={props => <AccountSettingsPage {...props} />}
            />

            <Route
              path="/counselor-profile/:id"
              name="Counselor Profile"
              render={props => <CounselorProfile {...props} />}
            />

            <Route
              path="/counselor-profile-staff/:id"
              name="Counselor Profile Staff"
              render={props => <CounselorProfileStaff  {...props} />}
            />

            <Route
              path="/counselor-profile-district/:id"
              name="Counselor Profile District"
              render={props => <CounselorProfileDistrict  {...props} />}
            />

            <Route
              path="/book-with-counselor/:id"
              name="Book session with Counselor"
              render={props => <CounselorCalendar {...props} />}
            />
            <Route
              path="/revenue"
              name="Revenue Section"
              render={props => <Revenue {...props} />}
            />
            <Route
              path="/documents"
              name="Documents Section"
              render={props => <DocumentsSection {...props} />}
            />
            <Route
              path="/points"
              name="Point Section"
              render={props => <PointsBreakdown {...props} />}
            />
            <Route
              path="/privacy-policy"
              name="privacy Section"
              render={props => <Privacy {...props} />}
            />
            <Route
              path="/terms"
              name="terms Section"
              render={props => <Terms {...props} />}
            />
            <Route
              path="/child-progress-temp"
              name="temporary page"
              render={props => <Temporary {...props} />}
            />
            <Route
              path="/uploadVideosForKids/:id"
              name="Upload Videos"
              render={props => <UploadVideoForKids {...props} />}
            />
            <Route
              path="/video-session/:id"
              name="Video session"
              render={props => <Videosession {...props} />}
            />
            <Route
              path="/rate-call/:id"
              name="Rate Call"
              render={props => <RateCall {...props} />}
            />
            <Route
              path="/event-booked/:id"
              name="Confirmed event"
              render={props => <ConfirmedEvent {...props} />}
            />
            <Route
              path="/school"
              name="School Page"
              render={props => <School {...props} />}
            />
            <Route
              path="/partner"
              name="School Page"
              render={props => <Partners {...props} />}
            />
            <Route
              path="/partners"
              name="School Page"
              render={props => <Partners {...props} />}
            />
            {/* <Route exact 
              path="/partner"
              name="Partner"              
              component={() => { 
                window.location.href = 'https://docsend.com/view/s/u242etckmhtp7zic'; 
                return null;
              }}
            />
            <Route exact 
              path="/partners"
              name="Partners"              
              component={() => { 
                window.location.href = 'https://docsend.com/view/s/u242etckmhtp7zic'; 
                return null;
              }}
            /> */}
            <Route exact 
              path="/toolkit"
              name="Toolkit"              
              component={() => { 
                window.location.href = 'https://docsend.com/view/s/hrjekq4msvuig6mi'; 
                return null;
              }}
            />            
            <Route
              path="/sessionList"
              name="Session List"
              render={props => <SessionList {...props} />}
            />
            <Route
              path="/feedback-success"
              name="Feedback Page"
              render={props => <FeedbackPage {...props} />}
            />

            <Route
              path="/payout-counselor"
              name="Payout Page"
              render={props => <PayoutForm {...props} />}
            />

            <Route
              path="/commercial"
              name="Commercial page"
              render={props => <Commercial {...props} />}
            />

            <Route
              path="/documents"
              name="Document page"
              render={props => <Documents {...props} />}
            />
            <Route
              path="/consent-form/:token?"
              name="Consent Form"
              render={props => <ConsentForm {...props} />}
            />
            <Route
              path="/ChildLoginParent"
              name="ChildLoginParent"
              render={props => <ChildLoginParent {...props} />}
            />

            <Route
              path="/LoginUserPrompt"
              name="LoginUserPrompt"
              render={props => <LoginUserPrompt {...props} />}
            />



            <Route
              path="/studentPasswordChange"
              name="StudentPasswordChange"
              render={props => <StudentPasswordChange {...props} />}
            />

            <Route
              exact
              path="/schoolAdmins"
              name="School Admin"
              render={props => <SchoolAdmins {...props} />}
            />

            <Route
              exact
              path="/districtAdmins"
              name="districtAdmin"
              render={props => <DistrictAdmins {...props} />}
            />

            <Route
              exact
              path="/howItWorks"
              name="districtAdmin"
              render={props => <HowItWorks {...props} />}
            />

            <Route
              exact
              path="/student-progress/:id"
              name="studentProgressDistrictView"
              render={props => <StudentProgressDistrictView {...props} />}
            />

            <Route
              exact
              path="/booking-history"
              name="Booking History"
              render={props => <BookingHistory {...props} />}
            />

            <Route  path="" component={PageNotFound} />

          </Switch>
        </BrowserRouter>
      </React.Suspense>
    );
  }
}


export default withRouter(App);
