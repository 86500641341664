import React, { Component } from "react";
import { Link, NavLink } from 'react-router-dom';
import { gapi } from 'gapi-script';

import {
   logout, getUserData, getAllsesssionListOnUser
} from "../../action/user_login_action";
import { connect } from "react-redux";
import url from '../../config/config';
import ReactCountdownClock from 'react-countdown-clock'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import toastr from 'toastr';

import {
   FormBuilder,
   FieldGroup,
   FieldControl,
   Validators
} from "react-reactive-form";
import {
   LoginAction, ForgetPassword
} from "../../action/user_login_action";
 
class Header extends Component {
   constructor(props) {
      super(props);
      this.state = {
         random: false,
         checkBlinking: false,
         countDown: '',
         // timeDay:0,
         // timeHr:0,
         // timeMin:0,
         // timeSec:0,
         finalTimeTimer: 0,
         openLoginModal: false,
         forgetPasswordState: false,
         openParentModal: false
      };

      this.loginForm = FormBuilder.group({
         email: ["", [Validators.required, Validators.email]],
         password: ["", Validators.compose([Validators.required])]
      });

      this.forgotForm = FormBuilder.group({
         email: ["", [Validators.required, Validators.email]],
      });
      
      this.loginFormParent = FormBuilder.group({
         email: ["", [Validators.required, Validators.email]],
      });

      this.submitForgotPassword = this.submitForgotPassword.bind(this)

      this.submitLoginOthers = this.submitLoginOthers.bind(this)

      this.submitLoginforParent = this.submitLoginforParent.bind(this)

   }

   async submitForgotPassword(e) {
      if (this.forgotForm.invalid) {
         toastr.error("Please first fill all required field.")
      } else {
         var data = {
            email: this.forgotForm.value.email.toLowerCase().trim(),
         };
         e.preventDefault()
         let result = await this.props.dispatch(ForgetPassword(data, this.props.history))
         if (result) {
            this.forgotForm.reset();
            this.setState({
               showResponseforForgot: true,
            });
         }
      }
   }



   async submitLoginOthers(e) {
      e.preventDefault()
      if (this.loginForm.invalid) {
         toastr.error("Please first fill all required field.")
      } else {
         // this.props.data.history.push({ pathname: "/sigin-parent-child" })
         // // toastr.error("Kids section comming Soon ....")

         var data = {

            email: this.loginForm.value.email.toLowerCase().trim(),
            password: this.loginForm.value.password
         };
         let result = await this.props.dispatch(LoginAction(data, this.props.data.history))
         if (result) {
            this.setState({

               openLoginModal: false,



            })
         }
      }
   }
   loginModal = () => {

      this.setState({
         openLoginModal: true
      })

   }
   pare

   toggle = () => {
      this.setState({
         openLoginModal: false,
         openParentModal: false
      })
   }

   forgetPasswordModal = () => {
      this.setState({
         forgetPasswordState: true
      })

   }

   forgotToogle = () => {
      this.setState({
         forgetPasswordState: false,

      })
   }

   ParentModal = () => {
      this.setState({

         openParentModal: true,


      });
   }
   
   async submitLoginforParent(e) {
      e.preventDefault()
      this.setState({
         // openCouncellorModal: false,
         // openParentModal: false,
         // openKidModal: false,
         showResponse: false
      })
      if (this.loginFormParent.invalid) {
         toastr.error("Please first fill all required field.")
      } else {
         // this.props.data.history.push({ pathname: "/sigin-parent-child" })
         var data = {
            email: this.loginFormParent.value.email.toLowerCase().trim(),
            role: 3,
         };
         let result = await this.props.dispatch(LoginAction(data, this.props.data.history))
         if (result) {
            this.loginFormParent.reset();
            this.setState({
               showResponse: true
            })
         }
      }
   }

   async componentDidMount() {
      let result = await this.props.dispatch(getUserData());      
      if (result) {
         this.getSession();
         this.userImage = this.props.loggedInUser.image
         this.setState({
            random: true
         })
      }
   }
     
   getSession = async () => {
      var data = {
         role: this.props.loggedInUser.role,
         id: this.props.loggedInUser._id
      }
      
      let result = await this.props.dispatch(getAllsesssionListOnUser(data));
       
      if (result) {
         result.forEach(element => {
            let actualtime = new Date(element.slotBooked[0].slotTime);

            let currentTime = new Date();

            var dif = actualtime - currentTime;

            var finaltime = Math.round((dif / 1000) / 60);

            // console.log(finaltime,"final")


            if (finaltime > 0) {

               this.setState({

                  finalTimeTimer: finaltime * 60

               })
               //    return(
               //       <div>
               //          <a href="#" className="startSession">
               // <span><b><img src="../../assets/images/clock.svg" alt="" />Session start's in
               // <Countdown date={finaltime*60*1000}
               // autoStart="true" />
               // </b></span></a>
               //       </div>
               //    )
            }


            // console.log(this.state.timeHr,this.state.timeMin)


            let remTime = finaltime + 30



            //console.log("Fffffff", finaltime)
            if (finaltime <= 5 && finaltime >= -30) {
               this.roomid = element.roomid;
               this.setState({
                  checkBlinking: true,
                  countDown: setInterval(remTime, 1000)
               })
            }
            if (remTime = 0) {
               window.location.href('/dashboard')
               this.setState({
                  checkBlinking: false,
                  countDown: '',
               })
            }
         })
      }
   
   }

   logout = async () => {
      if (gapi.auth2) {
         var auth2 = await gapi.auth2.getAuthInstance();
         auth2.signOut().then(function () {
         });
      }

      localStorage.removeItem('token');
      localStorage.removeItem('emotion');
      localStorage.removeItem('user');
      localStorage.removeItem('isChildLogin');
      localStorage.removeItem('isStudentLogin');

      localStorage.removeItem('calendar');
      localStorage.removeItem('messages');
      localStorage.removeItem('documents');
      localStorage.removeItem('revenue');
      // sessionStorage.clear();
      let result = await this.props.dispatch(logout(this.props.loggedInUser._id, this.props.data.history));
      if (result) {
         this.setState({
            random: !this.state.random
         })
      }
      // this.props.data.history.push({ pathname: "/" });
   }

   render() {
        
      return (
         <div>
            {localStorage.getItem('isChildLogin') === "true" || localStorage.getItem('isStudentLogin') === "true" ?
               <>

                  <header className="header pr-sm-5">
                     <nav className="" data-wow-delay="0.1s">


                        <div className="container">
                           {
                           //console.log(this.state.timeHead, "FINA")
                           }

                           <div className="row align-items-center">
                              <div className="col-10 col-md-8 d-flex align-items-center justify-content-between top-bar">
                                 <NavLink to="/dashboard" className="nav-link">
                                    <img alt=""
                                       className="img-fluid" id="header-logo" src="../../assets/images/logo.svg" />
                                 </NavLink>
                              </div>
                              <div className="col-md-4 ">
                                 <div className="headerIcons d-flex justify-content-end flex-wrap align-items-center">
                                    <div className="dropdown user-dropdown">
                                       {localStorage.getItem('isChildLogin') === "true" ? <button className="btn dropdown-toggle d-flex align-items-center font-fredoka" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                          <div className="image-avatar mr-2">
                                             {this.props.loggedInChild && this.props.loggedInChild.childImage ?
                                                <img src={this.props.loggedInChild.childImage} alt="" style={{ width: "30px" }} />
                                                :
                                                <img src="../../assets/images/user-icon.svg" alt="" />
                                             }
                                          </div>
                                          {this.props.loggedInChild && this.props.loggedInChild.nickName ? this.props.loggedInChild.nickName : null}
                                       </button>
                                          :
                                          <button className="btn dropdown-toggle d-flex align-items-center font-fredoka" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                             <div className="image-avatar mr-2">
                                                {this.props.loggedInStudent && this.props.loggedInStudent.studentImage ?
                                                   <img src={this.props.loggedInStudent.studentImage} alt="" style={{ width: "30px" }} />
                                                   :
                                                   <img src="../../assets/images/user-icon.svg" alt="" />
                                                }
                                             </div>
                                             {this.props.loggedInStudent && this.props.loggedInStudent.studentName ? this.props.loggedInStudent.studentName : null}
                                          </button>
                                       }

                                       {localStorage.getItem('isStudentLogin') === "true" ? <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          {/* {this.props.loggedInUser.finalStepVerified ?
                                             <NavLink to="/account-setting" className="nav-link">My Account</NavLink>
                                             : null
                                          } */}
                                          <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
                                          <NavLink to="/studentPasswordChange" className="nav-link">Update Password</NavLink>
                                          <NavLink to="/" onClick={this.logout} className="nav-link">Logout</NavLink>
                                       </div> :

                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                             {/* {this.props.loggedInUser.finalStepVerified ?
                                          <NavLink to="/account-setting" className="nav-link">My Account</NavLink>
                                          : null
                                       } */}
                                             <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
                                             <NavLink to="/" onClick={this.logout} className="nav-link">Logout</NavLink>
                                          </div>}

                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </nav>
                     {/* <!-- navbar --> */}
                  </header>

               </>
               :
               <>
                  {this.props.loggedInUser && this.props.loggedInUser !== '' ?
                     <>
                        <header className="header">
                           <nav className="" data-wow-delay="0.1s">


                              <div className="container">

                                 <div className="row align-items-center">
                                    <div className="col-10 col-lg-7 col-md-4 d-flex align-items-center justify-content-between top-bar">
                                       {this.props.loggedInUser && this.props.loggedInUser.role ?
                                          <>
                                             <NavLink to="/dashboard" className="nav-link">
                                                <img alt=""
                                                   className="img-fluid" id="header-logo" src="../../assets/images/logo.svg" />
                                             </NavLink>
                                          </>
                                          :
                                          <>
                                             <NavLink to="/" className="nav-link">
                                                <img alt=""
                                                   className="img-fluid" id="header-logo" src="../../assets/images/logo.svg" />
                                             </NavLink>
                                          </>}

                                       {/* <a className="navbar-brand" href="index.html"><img alt="" className="img-fluid" id="header-logo"
                                    src="../../assets/images/logo.svg" /></a> */}
                                       {this.props.loggedInUser && this.props.loggedInUser.role ?
                                          null :
                                          <>
                                             <li>
                                                <NavLink to="/counselor-page">
                                                   <strong className="color-primary media-counselor d-block  mr-3 mb-2 ">Grief Counselors</strong>
                                                </NavLink>
                                             </li>
                                             <li>
                                                <NavLink to="/school">
                                                   <strong className="color-secondary media-counselor d-block mb-2">Schools</strong>
                                                </NavLink>
                                             </li>

                                          </>
                                       }


                                    </div>

                                    <div className="col-lg-5 col-md-8 pr-sm-5  d-flex justify-content-between justify-content-sm-end session-button">
                                       <div className="headerIcons d-flex justify-content-end flex-wrap align-items-center  order-2 order-sm-1">
                                          {/* {this.props.loggedInUser && this.props.loggedInUser.role === 2 &&
                                 <>
                                 <a href="#" className="mr-3" >New <span className="value">4</span></a>
                                 <a href="#" className="relative mr-3"><img src="../../assets/images/favorite.svg" alt="" />
                                  <span className="value">4</span></a>
                                 <a href="#" className="relative mr-3"><img src="../../assets/images/messge-icon.svg" alt="" /> 
                                 <span className="value">45</span></a>
                                 </>
}
                                     */}
                                          <div className="dropdown user-dropdown">
                                             <button className="btn dropdown-toggle d-flex align-items-center font-fredoka" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div className="image-avatar mr-2">
                                                   {this.props.loggedInUser && this.props.loggedInUser.image ?
                                                      <img src={this.props.loggedInUser.image} alt="" style={{ width: "30px" }} />
                                                      :
                                                      <img src="../../assets/images/user-icon.svg" alt="" />
                                                   }
                                                </div> <span className="u-name">{this.props.loggedInUser.firstName}</span>
                                             </button>
                                             <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                {this.props.loggedInUser.finalStepVerified ?
                                                   <NavLink to="/account-setting" className="nav-link">My Account</NavLink>
                                                   : null
                                                }
                                                <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
                                                {/* {this.props.loggedInUser && this.props.loggedInUser.role === 5 && <NavLink to="/invite" className="nav-link">Invite</NavLink>} */}
                                                <NavLink to="/" onClick={this.logout} className="nav-link">Logout</NavLink>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="rightBtn my-2 text-left text-sm-center order-1 order-sm-2">
                                          
                                          {  
                                             this.props.loggedInUser.role === 6 ?
                                                <div>
                                                </div>
                                                : this.state.checkBlinking ?
                                                   <>
                                                      <NavLink to={`/video-session/${this.roomid}`} className="startSession blinking">
                                                         {/* <span><b><img src="../../assets/images/clock.svg" alt="" /> {setInterval(this.state.countDown,1000*60)} mins left</b></span> */}
                                                         <span><b><img src="../../assets/images/clock.svg" alt="" /> Join Session</b></span>


                                                      </NavLink>
                                                   </>

                                                   :
                                                   <>
                                                      <a href="#" className="startSession">
                                                         <span><b><img src="../../assets/images/clock.svg" alt="" />Start Counseling Session</b></span>
                                                      </a>
                                                      {/* <span>
             {console.log(this.state.finalTimeTimer,"final")}   
             {this.state.finalTimeTimer ? <>
             

               <div className="timer-wrapper">
        <CountdownCircleTimer 
        {...this.timerProps}
        
          isPlaying
          duration={this.state.finalTimeTimer}
          colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
          onComplete={() => [true, 1000]}
        >
          {this.renderTime}
        </CountdownCircleTimer>
        
        
        
      </div>
             
             </>:
             <>
             <a href="#" className="startSession">
             <span><b><img src="../../assets/images/clock.svg" alt="" />Start Counseling Session</b></span></a>
             
             </>}
                                                 
                                                     </span>   */}



                                                      {/*                                                      
                                                     <a href="#" className="startSession">
             <span><b><img src="../../assets/images/clock.svg" alt="" />Session start's in
             <Countdown date={Date.now()+ stratTime}
             autoStart="true" />
             </b></span></a>
 */}


                                                      {/* 
<ReactCountdownClock seconds={this.state.finalTimeTimer}
                     color="#EF6F71"
                     alpha={0.8}
                     size={70}
                     weight={7}
                     showMilliseconds="true"
                     onComplete={this.getSession}
                      />
              */}




                                                   </>

                                          }

                                          {/* <span className="sessio-itme"> <strong>20 Minutes</strong> to go.</span> */}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </nav>
                           {/* <!-- navbar --> */}
                        </header>

                     </>
                     :
                     <>
                        <header className="header">
                           {/* navbar  */}
                           <nav className="navbar navbar-light navbar-expand-lg navbar-header wow fadeInDown" data-wow-delay="0.1s">
                              <div className="container">
                                 <NavLink to="/" className="nav-link">
                                    {/* <a className="navbar-brand" href="index.html"> */}
                                    <img alt="" className="img-fluid" id="header-logo" src="../../assets/images/logo.svg" />
                                 </NavLink>
                                 <button aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation"
                                    className="navbar-toggler collapsed" data-target="#navbarToggle" data-toggle="collapse" type="button">
                                    <span className="navbar-toggler-icon"></span></button>
                                 <div className="collapse navbar-collapse" id="navbarToggle">
                                    <ul className="navbar-nav nav ml-auto align-items-center">
                                       {this.props.loggedInUser && this.props.loggedInUser.role ?
                                          null :
                                          <>
                                             {/* <li>
                                                <NavLink to="#" onClick={() => {window.open('https://docsend.com/view/s/u242etckmhtp7zic');}}>
                                                   <strong className="color-primary media-counselor d-block  mr-3 mb-2">Partner</strong>
                                                </NavLink>
                                             </li> */}
                                             <li>
                                                <NavLink to="/">
                                                   <strong className="color-primary media-counselor d-block  mr-3 mb-2">Families</strong>
                                                </NavLink>
                                             </li>
                                             <li>
                                                <NavLink to="/school">
                                                   <strong className="color-primary media-counselor d-block mb-2">Schools</strong>
                                                </NavLink>
                                             </li>
                                          </>
                                       }
                                       <li className="nav-item pr-0">
                                          {/* <a className="nav-link btn btn-border font-fredoka " href="#" data-toggle="modal" data-target="#loginuserPrompt2" onClick={this.loginModal}>Login </a> */}
                                          <a className="nav-link btn btn-border font-fredoka " href="#" data-toggle="modal" data-target="#loginuserPrompt">Login</a>


                                       </li>
                                       <li className="nav-item">
                                          <a className="nav-link btn btn-primary font-fredoka" href="#" data-toggle="modal" data-target="#signupuserPrompt">Sign Up</a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </nav>
                           {/* <!-- navbar --> */}
                        </header>


                        <Modal className="loginmodal modal-bg modal-hdr-img modal-dialog-centered" isOpen={this.state.openLoginModal} toggle={this.toggle} >
                           <ModalHeader toggle={this.toggle}>
                              <div className="text-center">
                                 <h5 className="font-fredoka" id="exampleModalLabel">Login</h5>
                                 {/* <img src="assets/images/counselor-login.svg" className="top-img" alt="" /> */}
                                 {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
                              </div>
                           </ModalHeader>
                           <ModalBody>
                              <div className="px-4">
                                 <FieldGroup
                                    control={this.loginForm}
                                    render={({ get, invalid }) => (
                                       <form onSubmit={this.submitLoginOthers}>
                                          <FieldControl
                                             name="email"
                                             render={({ handler, touched, hasError, meta }) => (
                                                <div>
                                                   <div className="form-group">
                                                      <label for="">Email</label>
                                                      <input type="email" className="form-control" placeholder="" {...handler()} />
                                                      <span className="pro-error" style={{ color: "red" }}>
                                                         {touched &&
                                                            ((hasError("required") && `Required`) ||
                                                               (hasError("email") &&
                                                                  "Please enter a valid email"))}
                                                      </span>
                                                   </div>

                                                </div>
                                             )}
                                          />
                                          <FieldControl
                                             name="password"
                                             render={({ handler, touched, hasError, meta }) => (
                                                <div>
                                                   <div className="form-group">
                                                      <label for="">Password</label>
                                                      <input type="password" className="form-control" placeholder=""  {...handler()} />
                                                      <span className="pro-error" style={{ color: "red" }}>
                                                         {touched &&
                                                            ((hasError("required") && `Required`))}
                                                      </span>
                                                   </div>

                                                </div>
                                             )}
                                          />
                                          <div className="d-flex justify-content-between align-items-center">
                                             {/* <div className="custom-control custom-checkbox align-items-center d-flex">
                                                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                    <label className="custom-control-label" for="customCheck1"> Remember Me</label>
                                                </div> */}
                                             <a href="#" onClick={this.forgetPasswordModal} className="link">Forgot Password?</a>
                                          </div>
                                          <div className="text-center py-3">
                                             <input type="submit" value="Login" className="btn btn-primary btn-lg font-fredoka" />
                                          </div>
                                       </form>
                                    )}
                                 />
                              </div>
                              <div className="modal-footer text-center">
                                 <a href="/" data-toggle="modal" data-target="#signupuserPrompt" data-dismiss="modal" className="link link-modal-bottom" onClick={this.toggle}>Create Account</a>
                              </div>

                              <div className="modal-footer text-center">
                                 <a href="" data-toggle="modal" data-target="#loginuserPrompt6" data-dismiss="modal" className="link link-modal-bottom" onClick={this.ParentModal}>Parent/Guardian Login</a>
                              </div>

                           </ModalBody>
                        </Modal>



                        <Modal className="loginmodal modal-bg modal-dialog-centered" isOpen={this.state.forgetPasswordState} toggle={this.forgotToogle} >
                           <ModalHeader toggle={this.forgotToogle}>
                              <div className="text-center">
                                 <h5 className="font-fredoka" id="exampleModalLabel">Forgot Password</h5>
                              </div>
                           </ModalHeader>
                           <ModalBody>
                              <div className="px-4">
                                 <FieldGroup
                                    control={this.forgotForm}
                                    render={({ get, invalid }) => (
                                       <form onSubmit={this.submitForgotPassword}>
                                          <FieldControl
                                             name="email"
                                             render={({ handler, touched, hasError, meta }) => (
                                                <div>
                                                   <div className="form-group mt-3">
                                                      <label for="">Email</label>
                                                      <input type="email" className="form-control" placeholder="" {...handler()} />
                                                      <span className="pro-error" style={{ color: "red" }}>
                                                         {touched &&
                                                            ((hasError("required") && `Required`) ||
                                                               (hasError("email") &&
                                                                  "Please enter a valid email"))}
                                                      </span>
                                                   </div>

                                                </div>
                                             )}
                                          />
                                          <div className="text-center py-3">
                                             <input type="submit" value="Reset Password" className="btn btn-primary btn-lg font-fredoka" />
                                          </div>

                                       </form>



                                    )}
                                 />
                                 {this.state.showResponseforForgot &&
                                    <>
                                       <span className="success-message mt-3">A password recovery email was sent to your registered email address. Please check your email to change password.</span>
                                    </>
                                 }
                              </div>
                              {/* <div className="modal-footer text-center">
                            <a href="/" data-toggle="modal" data-target="#signupuserPrompt" data-dismiss="modal" className="link link-modal-bottom">Create Account</a>
                        </div> */}
                           </ModalBody>
                        </Modal>


                        {/* login form for parents */}
                        <Modal className="loginmodal modal-bg modal-hdr-img modal-dialog-centered" isOpen={this.state.openParentModal} toggle={this.toggle} >
                           <ModalHeader toggle={this.toggle}>
                              <div className="text-center">
                                 <h5 className="font-fredoka" id="exampleModalLabel">Parent/Guardian Login</h5>
                                 <img src="assets/images/parent-login.svg" className="top-img" alt="" />
                                 {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
                              </div>
                           </ModalHeader>
                           <ModalBody>
                              <div className="px-4">
                                 <FieldGroup
                                    control={this.loginFormParent}
                                    render={({ get, invalid }) => (
                                       <form onSubmit={this.submitLoginforParent}>
                                          <FieldControl
                                             name="email"
                                             render={({ handler, touched, hasError, meta }) => (
                                                <div>
                                                   <div className="form-group">
                                                      <label for="">Email</label>
                                                      <input type="email" className="form-control" placeholder="" {...handler()} />
                                                      <span className="pro-error" style={{ color: "red" }}>
                                                         {touched &&
                                                            ((hasError("required") && `Required`) ||
                                                               (hasError("email") &&
                                                                  "Please enter a valid email"))}
                                                      </span>
                                                   </div>

                                                </div>
                                             )}
                                          />
                                          <div className="d-flex justify-content-between align-items-center">
                                             {/* <div className="custom-control custom-checkbox align-items-center d-flex">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label" for="customCheck1"> Remember Me</label>
                                        </div> */}
                                             <a href="#" onClick={this.forgetPasswordModal} className="link">Forgot Password?</a>
                                          </div>
                                          <div className="text-center py-3">
                                             <input type="submit" value="SignIn with Magic Link" className="btn btn-primary btn-lg font-fredoka" />
                                          </div>

                                       </form>
                                    )}
                                 />
                                 {this.state.showResponse &&
                                    <>
                                       <span className="success-message">A magic link has been sent to your email account.
                                          Kindly use that link to login.</span>
                                    </>
                                 }
                              </div>
                              <div className="modal-footer text-center">
                                 <a href="/" data-toggle="modal" data-target="#signupuserPrompt" data-dismiss="modal" onClick={this.toggle} className="link link-modal-bottom">Create Account</a>
                              </div>
                           </ModalBody>
                        </Modal>





                     </>
                  }
               </>
            }

         </div>
      );
   }
}


function mapStateToProps(state, ownProps) {
   return {
      loggedInUser: state.detailR.loggedInUser,
      loggedInChild: state.childReducerData.loggedInChild,
      loggedInStudent: state.childReducerData.loggedInStudent

   };
}

export default connect(mapStateToProps)(Header)

