import React, { Component } from "react";
import { Link, NavLink } from 'react-router-dom';
import { connect } from "react-redux";

class Footer extends Component {
   constructor(props) {
      super(props);
      this.state = {

      };
   }

   facebookFunction = () => {
      window.open('https://facebook.com/guardianlane', '_blank');
   }

   instargramFunction = () => {
      window.open('https://instagram.com/guardianlane', '_blank');
   }

   loading = () => (
      <div className="animated fadeIn pt-1 text-center">Loading...</div>
   );
   render() {
      return (
         <div>
            <footer>
               <div className="container">
                  <div className="row">
                     <div className="col-lg-4 d-none">
                        <h5>Subscribe to newsletter</h5>
                        {/* <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p> */}
                        <form action="">
                           <div className="form-group mt-4">
                              <input type="email" className="form-control" placeholder="Email Address" />
                              <button type="submit" className="btn btn-primary">subscribe</button>
                           </div>
                        </form>
                     </div>
                     <div className="col-lg-12 d-flex justify-content-between flex-column flex-sm-row">
                        <div className="footer-links mr-sm-3">
                           <div className="links">
                              <h5>Links</h5>
                              <NavLink to="/">
                                 <strong className="color-primary">Home</strong>
                              </NavLink>
                              <NavLink to="/counselor-page">
                                 <strong className="color-primary">Grief Counselors</strong>
                              </NavLink>
                              <NavLink to="/school">
                                 <strong className="color-primary">Schools</strong>
                              </NavLink>
                              <NavLink to="/partner">
                                 <strong className="color-primary">Partner</strong>
                              </NavLink>
                              <NavLink to="/terms">
                                 <strong className="color-primary">Terms and Conditions</strong>
                              </NavLink>
                              <NavLink to="/privacy-policy">
                                 <strong className="color-primary">Privacy Policy</strong>
                              </NavLink>
                              <NavLink to="/commercial">
                                 <strong className="color-primary">Commercials</strong>
                              </NavLink>
                              {/* <NavLink to="#" onClick={() => {window.open('https://docsend.com/view/s/u242etckmhtp7zic');}}>
                                 <strong className="color-primary">Partner</strong>
                              </NavLink> 
                              onClick={() => {window.open('https://docsend.com/view/s/hrjekq4msvuig6mi');}} */}
                              <NavLink to="/toolkit">
                                 <strong className="color-primary">School Toolkit</strong>
                              </NavLink>                              
                              <NavLink to="animation-series">
                                 <strong className="color-primary">Animation Series</strong>
                              </NavLink>  
                              {this.props && this.props.loggedInUser ?

                                 null : 
                                 <>
                                  {/* <li className="nav-item"> */}
                                  <a className="color-primary" href="#" data-toggle="modal" data-target="#signupuserPrompt">Sign Up</a>
                                 {/* </li> */}
                                 {/* <li className="nav-item pr-0"> */}
                                 <a className="color-primary" href="#" data-toggle="modal" data-target="#loginuserPrompt">Login</a>
                                 {/* </li> */}                               
                              </>
                              }
                              <a className="color-primary" href="https://myforeverguardian.com/" target="_blank" rel="noreferrer">Our Book</a>
                              <NavLink to="/blog">
                                 <strong className="color-primary">Blog</strong>
                              </NavLink>
                           </div>
                        </div>
                        <div className="footer-links ">
                           <h5>Contact</h5>
                           <div className="links">
                              {/* <a href="tel:+44 345 678 903">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="10.727" height="18.667" viewBox="0 0 10.727 18.667">
                                    <path id="Path_1684" data-name="Path 1684" d="M1056.391,38.486h-6.114v-.777a.94.94,0,1,0-1.88,0V38.5a1.455,1.455,0,0,0-1.282,1.44V53.986a1.455,1.455,0,0,0,1.451,1.45h7.826a1.455,1.455,0,0,0,1.45-1.45V39.937A1.455,1.455,0,0,0,1056.391,38.486Zm-5.273,12.086c0,.084-.122.153-.272.153h-2.228c-.149,0-.272-.069-.272-.153V49.236c0-.084.122-.152.272-.152h2.228c.15,0,.272.069.272.152Zm2.137,0a.153.153,0,0,1-.152.153h-1.249a.153.153,0,0,1-.152-.153V49.236a.153.153,0,0,1,.152-.152h1.249a.153.153,0,0,1,.152.152Zm3.354,0c0,.084-.124.153-.276.153h-2.267c-.152,0-.277-.069-.277-.153V49.236c0-.084.125-.152.277-.152h2.267c.152,0,.276.069.276.152Zm0-2.907a.813.813,0,0,1-.81.81h-6.642a.813.813,0,0,1-.81-.81V40.559a.812.812,0,0,1,.81-.81h6.642a.813.813,0,0,1,.81.81Z" transform="translate(-1047.114 -36.769)" fill="#3a4145" />
                                 </svg>
                                 +44 345 678 903
                           </a> */}
                              <a href="mailto:INFO@GUARDIANLANE.COM">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16.823" height="12.282" viewBox="0 0 16.823 12.282">
                                    <g id="Group_518" data-name="Group 518" transform="translate(-1192.142 -36.769)">
                                       <path id="Path_1685" data-name="Path 1685" d="M1192.142,59.947v8.317l5-4.16Z" transform="translate(0 -20.59)" fill="#3a4145" />
                                       <path id="Path_1686" data-name="Path 1686" d="M1207.791,36.769h-14.168a1.333,1.333,0,0,0-1.308,1.1l6.61,5.5,1.1.916a1.108,1.108,0,0,0,1.361,0l1.158-.963,6.554-5.45A1.333,1.333,0,0,0,1207.791,36.769Z" transform="translate(-0.154 0)" fill="#3a4145" />
                                       <path id="Path_1687" data-name="Path 1687" d="M1303.031,68.265V59.947l-5,4.158Z" transform="translate(-94.067 -20.59)" fill="#3a4145" />
                                       <path id="Path_1688" data-name="Path 1688" d="M1206.1,104.686l-.9.744a1.108,1.108,0,0,1-1.361,0l-1.154-.96-.674-.561-5.4,4.493a1.321,1.321,0,0,0,.828.292h14.168a1.32,1.32,0,0,0,.828-.292l-5.4-4.493Z" transform="translate(-3.975 -59.644)" fill="#3a4145" />
                                    </g>
                                 </svg>
                                 INFO@GUARDIANLANE.COM
                              </a>
                           </div>
                           <div className="social-links mt-3">
                              <h5>Follow Us</h5>
                              <div className="d-flex">
                                 <a href="" onClick={this.facebookFunction}><i className="fab fa-facebook-f"></i></a>
                                 {/* <a href="https://instagram.com/guardianlane"><i className="fab fa-twitter"></i></a> */}
                                 <a href="" onClick={this.instargramFunction}><i className="fab fa-instagram"></i></a>
                                 {/* <a href="#"><i className="fab fa-youtube"></i></a> */}
                              </div>
                           </div>
                        </div>
                        {/* <div className="footer-links ">
                           <h5>Resources</h5>
                           <div className="links">
                              <a href="#">Blog</a>
                              <a href="#">Videos</a>
                           </div>
                        </div>
                        <div className="footer-links ">
                           <h5>Grief Emotions</h5>
                           <div className="links">
                              <a href="#">Fear</a>
                              <a href="#">Sadness</a>
                              <a href="#">Anxiety</a>
                              <a href="#">pain</a>
                              <a href="#">angry</a>
                              <a href="#">distracted</a>
                              <a href="#">blame</a>
                           </div>
                        </div>
                     */}
                     </div>
                  </div>
               </div>
            </footer>
            <div className="copyright">
               <span>© All Rights Reserved Guardian Lane {new Date().getFullYear()}</span>
               <img src="../../assets/images/copyright-bg1.svg" width="100%" alt="" />
            </div>
         </div>
      );
   }
}

function mapStateToProps(state, ownProps) {
   return {
      loggedInUser: state.detailR.loggedInUser
   };
}

export default connect(mapStateToProps)(Footer);
