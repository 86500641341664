import { combineReducers } from "redux";
import userReducer from "./user_Reducer.js";
import videoReducer from "./video_Reducer.js";
import childReducer from "./childReducer";
const rootReducer = combineReducers({
  detailR: userReducer,
  videoData: videoReducer,
  childReducerData: childReducer
});
export default rootReducer;







